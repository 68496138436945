import SettingsFeaturesList from "./list";
import SettingsFeaturesNew from "./new";


if (document.body.getAttribute("data-current-path") === "settings/features") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new SettingsFeaturesList();
    }else if (action === "new") {
        new SettingsFeaturesNew();
    }
}