import UtilsEntity from "@/frontend/entrypoints/utils/entity";
import flatpickr from "flatpickr";
import {German} from "flatpickr/dist/l10n/de";
import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'

import Swal from 'sweetalert2';

import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpProductEntity from '../entity';

export default class ErpProductList extends DefaultController {
    tableElem: any;
    datatable: any;

    async init() {
        this.entity = "erp/products"
        this.tableElem = jQuery('.datatables-users')
        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return ErpProductEntity.getEntityData(elem)
    }

    bindListeners() {
        this.tableElem.delegate(".delete-record", "click", async (e: any) => {
            const confirm = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if (confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id");
                const r = await Utils.entity.destroy(id, this.entity);
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });
        jQuery("body").delegate(".new_product", "click", (e: any) => {
            const href = e.currentTarget.getAttribute("href");
            document.location.href = href;
        })
    }

    createTable() {
        this.datatable = new DataTable('.datatables-users', {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            responsive: true,
            layout: {
                topEnd: ['search', 'buttons']
            },
            pageLength: 25,
            serverSide: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            ajax: {
                url: "/api/v1/erp/products",
                dataType: 'json',
                // @ts-ignore
                data: (params: { length: any; start: any; draw: any; order: any; search: any; columns: any; }) => {
                    console.log("aaa", params)
                    return {
                        limit: params.length,
                        page: params.start / 25 + 1,
                        draw: params.draw,
                        search: params.search ? params.search.value : null,
                        sort: params.order ? params.order[0] : [],
                        columns: params.columns
                    };
                },
                // @ts-ignore
                dataSrc: (data: any, params: any) => {
                    if (data.status === 200) {
                        data["recordsTotal"] = data.meta.total;
                        data["recordsFiltered"] = data.meta.total;
                        data["draw"] = data.meta.draw;
                    }
                    return data.data;
                },
                cache: true
            },
            select: true,
            columns: [
                {data: 'number'},
                {data: 'uuid'},
                {data: 'name'},
                {data: 'stock'},
                {data: 'manufacturer'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<a href="/${(<any>window).currentLocale}/erp/products/${full.uuid}">${data}</a>`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.category ? full.category.name : "";
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 4,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data ? data.name : "";
                    },
                },
                {
                    targets: 5,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="/${(<any>window).currentLocale}/erp/products/${data}" class="text-body">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2 new_product',
                    attr: {
                        'href': `/${(<any>window).currentLocale}/erp/products/new`
                    }
                }
            ]
        });
        setTimeout(() => {
            console.log("this.datatable", this.datatable)
            this.datatable.columns().every(async (col: any) => {
                if (col === 1) {
                    const column = this.datatable.column(col);
                    const header = column.header();
                    let filterElem: any = null

                    filterElem = document.createElement('select');
                    filterElem.multiple = true;
                    filterElem.classList.add("form-select")
                    header.appendChild(filterElem);
                    filterElem.style.marginTop = "5px"
                    header.appendChild(filterElem);

                    const options: any = {}
                    options[col] = (await Utils.entity.getAll("product_categories")).data.map((d: any) => d.name);
                    Object.keys(options).forEach((key: any) => {
                        const v = options[key].filter((value: any, index: any, array: string | any[]) => array.indexOf(value) === index);
                        v.forEach((value: any) => {
                            if (filterElem.add) {
                                filterElem.add(new Option(value, value));
                            }
                        })
                    })

                    const savedFilters = JSON.parse(localStorage.getItem("fltools_products_filter") as string)
                    if (savedFilters && savedFilters[`col-${col}`]) {
                        const values = savedFilters[`col-${col}`].split("|")
                        values.forEach((v: any) => {
                            if (filterElem.nodeName === "SELECT") {
                                if (filterElem.querySelector(`option[value='${v}']`)) {
                                    filterElem.querySelector(`option[value='${v}']`).setAttribute("selected", "true")
                                }
                            } else {
                                filterElem.value = savedFilters[`col-${col}`].replaceAll("\\.", ".").replaceAll("\\+", "+")
                            }
                        })
                        const ev = new Event("change")
                        filterElem.dispatchEvent(ev)
                        this.datatable.settings()[0].jqXHR.abort()
                        column.search(values.join("|").length > 0 ? '^' + values.join("|") + '$' : '', true, false).draw();
                    }
                    console.log(filterElem.nodeName)
                    if (filterElem.nodeName === "SELECT") {
                        jQuery(filterElem).select2().on('select2:select select2:unselect', (e: any) => {
                            //@ts-ignore
                            const values = Array.from(filterElem.querySelectorAll("option:checked")).map((e) => {
                                return e.value;
                            })
                            console.log(values)
                            let savedFilters = JSON.parse(localStorage.getItem("fltools_products_filter") as string)
                            if (!savedFilters) {
                                savedFilters = {}
                            }
                            savedFilters[`col-${col}`] = values.join("|")
                            localStorage.setItem("fltools_products_filter", JSON.stringify(savedFilters))
                            this.datatable.settings()[0].jqXHR.abort()
                            column.search(values.join("|").length > 0 ? '^' + values.join("|") + '$' : '', true, false).draw();
                        });
                    }
                }
            });
            console.log("tt", this.tableElem)
            const paginationSelect = this.tableElem.closest(".card").find("div.dataTables_length");
        }, 0)
    }
}