import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import TicketServiceMapEntity from "../../../ticket_service_map/entity";
import Calendar from '@event-calendar/core';
import TimeGrid from '@event-calendar/time-grid';
import Interaction from '@event-calendar/interaction';


export default class TicketShowChildren {
    private parent: any;
    private tableElem: any;
    private datatable: any;


    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.tableElem = jQuery('.contract_show_children')
        this.createTable();
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {}
    }

    bindListeners() {

    }

    createTable() {
        this.datatable = new DataTable(this.tableElem, {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/tickets/${this.parent.id}/children`,
            columns: [
                {data: 'name'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<a href="/${(<any>window).currentLocale}/ticket/tasks/${full.uuid}">${data}</a>`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.status) {
                            return `${full.status.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.ticket_queues && full.ticket_queues.length > 0) {
                            return `${full.ticket_queues.map(q => q.name).join(", ")}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.assignee) {
                            return `${full.assignee.name}`;
                        } else {
                            return "";
                        }
                    },
                },
            ],
            processing: true,
            dom:
                '<"row mb-2 w-100 align-items-center justify-content-between"' +
                '<"col-3 col-md-1 dt-info-l"<""l>>' +
                '<"col-9 col-md-2 dt-info-r"<"dt-action-buttons"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-7"i>' +
                '<"col-5 d-flex justify-content-end mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }
}