
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpProductEntity from "../entity";
import ErpProductGeneral from "./show/general";
export default class ErpProductNew extends DefaultController {
    async init() {
        this.entity = "erp/products"
        this.childs = {
            general: new ErpProductGeneral(this)
        }
        await super.init();
        await this.setCurrency();
    }

    protected getEntityData(elem: any) {
        return ErpProductEntity.getEntityData(elem)
    }
    async setCurrency() {
        const r = await Utils.entity.getAll("erp/currencies")
        if (r.status === 200) {
            r.data.forEach((currency: any) => {
                if(currency.isoCode === "EUR") {
                    (document.querySelector("#erpProductAddForm #erp_product_purchasing_price_currencyId") as HTMLInputElement).value = currency.id;
                    (document.querySelector("#erpProductAddForm #erp_product_price_currencyId") as HTMLInputElement).value = currency.id;
                }
            })
        }
    }

    bindListeners() {

        (document.querySelector("#erpProductAddForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const form = document.querySelector("#erpProductAddForm") as HTMLFormElement;

            const valid = form.checkValidity();
            if (valid) {
                await Utils.showLoader();
                const data = this.getEntityData(form)
                if (data.manufacturerName) {
                    const manufacturersResult = await Utils.entity.getAll('erp/product_manufacturers')
                    const manufacturers = manufacturersResult.data
                    let existingManufacturer = manufacturers.filter((f: any) => {
                        return f.name === data.manufacturerName
                    })[0]
                    if (!existingManufacturer) {
                        existingManufacturer = await Utils.entity.upsert({
                            id: null,
                            name: data.manufacturerName
                        }, 'erp/product_manufacturers')
                        existingManufacturer = existingManufacturer.data
                    }
                    data.manufacturerId = existingManufacturer.id

                }
                const r = await Utils.entity.upsert(data, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                    setTimeout(() => {
                        document.location.href = `/${(window as any).currentLocale}/erp/products/${r.data[0].uuid}`
                    }, 1000)
                } else {
                    const errors: any = [];
                    r.data.errors.forEach((err: any) => {
                        errors.push(`${Utils.translate(`erp.product.messages.${err.code}`)}`)
                    })
                    this.toastr.error(`${errors.join(", ")}`, `${Utils.translate('generic.error')}`)
                    await Utils.hideLoader();
                }
            }
        });
    }
}