
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpProductEntity from "../entity";
import ErpProductGeneral from "./show/general";
import ErpProductErp from "./show/erp";
import ErpProductStock from "./show/stock";
import ErpProductSupplier from "./show/supplier";


export default class ErpProductShow extends DefaultController {
    private dragElements: any[] = []
    private multiMoveModal: any;
    async init() {
        this.entity = "erp/products"
        this.multiMoveModal = bootstrap.Modal.getOrCreateInstance((document.querySelector("#erpProductMultiMoveModal") as HTMLElement))
        this.childs = {
            general: new ErpProductGeneral(this),
            erp: new ErpProductErp(this),
            stock: new ErpProductStock(this),
            supplier: new ErpProductSupplier(this)
        }
        await super.init();
    }

    protected getEntityData(elem: any) {
        return ErpProductEntity.getEntityData(elem)
    }
    bindListeners() {
        window.addEventListener("dragstart", (e) => {
            if (e.dataTransfer) {
                const target = e.target as HTMLElement
                e.dataTransfer.dropEffect = "copy";
                const customerId = target.getAttribute("data-customer-id");
                const warehouseLocationId = target.getAttribute("data-warehouse-location-id");
                const warehousePalletId = target.getAttribute("data-warehouse-location-pallet-id");
                const serial = target.getAttribute("data-serial")
                const title = serial && serial.length > 0 ? serial : target.getAttribute("data-title")
                e.dataTransfer.setData("text/plain",JSON.stringify({
                    title, customerId, warehouseLocationId, warehousePalletId, serial
                }));
            }
        });
        (document.querySelector(".dropzone") as HTMLElement).addEventListener("dragover", (e) => {
            e.preventDefault();
            if (e.dataTransfer) {
                e.dataTransfer.dropEffect = "move";
            }
        });
        (document.querySelector(".dropzone") as HTMLElement).addEventListener("drop", (e) => {
            if (e.dataTransfer) {
                const data = JSON.parse(e.dataTransfer.getData("text/plain"));
                let dragElements = JSON.parse(localStorage.getItem("tickware_erp_product_multi_move") as string)
                if (!dragElements) {
                    dragElements = {}
                }
                // @ts-ignore
                if (!dragElements[this.id]) {
                    // @ts-ignore
                    dragElements[this.id] = {
                        name: `${this.data.name}, ${this.data.number}`,
                        elements: []
                    }
                }
                // @ts-ignore
                dragElements[this.id].elements.push(data)
                // @ts-ignore
                dragElements[this.id].elements = dragElements[this.id].elements.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                        t.serial === value.serial && t.customerId === value.customerId && t.warehouseLocationId === value.warehouseLocationId && t.warehousePalletId === value.warehousePalletId
                        ))
                )
                localStorage.setItem("tickware_erp_product_multi_move", JSON.stringify(dragElements))
                this.generateDragTable()
            }
        });
        (document.querySelector("#erp_product_save") as HTMLElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const parent = document.querySelector(".row[data-entity-id]");
            await Utils.showLoader();
            const data = this.getEntityData(parent)
            if (data.manufacturerName) {
                const manufacturersResult = await Utils.entity.getAll('erp/product_manufacturers')
                const manufacturers = manufacturersResult.data
                let existingManufacturer = manufacturers.filter((f: any) => {return f.name === data.manufacturerName})[0]
                if (!existingManufacturer) {
                    existingManufacturer = await Utils.entity.upsert({
                        id: null,
                        name: data.manufacturerName
                    }, 'erp/product_manufacturers')
                    existingManufacturer = existingManufacturer.data
                }
                data.manufacturerId = existingManufacturer.id
                console.log("existingManufacturer", existingManufacturer)

            }
            const r = await Utils.entity.upsert(data, this.entity)
            if (r.status === 200) {
                await this.getEntity()
                this.toastr.success(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
            } else {
                const errors: any = [];
                r.data.errors.forEach((err: any) => {
                    errors.push(`${Utils.translate(`erp.product.messages.${err.code}`)}`)
                })
                this.toastr.error(`${errors.join(", ")}`, `${Utils.translate('generic.error')}`)
                await Utils.hideLoader();
            }
        });

        (document.querySelector("#erp_product_duplicate") as HTMLElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const parent = document.querySelector(".row[data-entity-id]");
            await Utils.showLoader();
            const data = this.getEntityData(parent)
            if (data.manufacturerName) {
                const manufacturersResult = await Utils.entity.getAll('erp/product_manufacturers')
                const manufacturers = manufacturersResult.data
                let existingManufacturer = manufacturers.filter((f: any) => {return f.name === data.manufacturerName})[0]
                if (!existingManufacturer) {
                    existingManufacturer = await Utils.entity.upsert({
                        id: null,
                        name: data.manufacturerName
                    }, 'erp/product_manufacturers')
                    existingManufacturer = existingManufacturer.data
                }
                data.manufacturerId = existingManufacturer.id
                console.log("existingManufacturer", existingManufacturer)

            }
            await Utils.entity.upsert(data, this.entity)
            data.uuid = null;
            data.number = `${data.number}_1`
            const r = await Utils.entity.upsert(data, this.entity)
            if (r.status === 200) {
                this.toastr.success(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.messages.duplicated')}`, `${Utils.translate('generic.success')}`)
                setTimeout(() => {
                    document.location.href = `/${(window as any).currentLocale}/erp/products/${r.data[0].uuid}`
                }, 1000)
            }
        });

        (document.querySelector("#erp_product_print") as HTMLButtonElement).addEventListener("click", async (e: any) => {
           e.preventDefault();
            const requestData = await Utils.erp.downloadLabel(this.data.id, "product")
            if (requestData.status === 200) {
                const pdf = requestData.data.pdf
                const arrayBuffer = Utils.base64ToArrayBuffer(pdf);
                Utils.createAndDownloadBlobFile(arrayBuffer, 'labels');
            }
        });

        (document.querySelector("#erp_product_delete") as HTMLElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const parent = document.querySelector(".row[data-entity-id]");
            await Utils.showLoader();
            const data = this.getEntityData(parent)

            const r = await Utils.entity.destroy(data.id, this.entity)
            if (r.status === 200) {

                this.toastr.success(`${Utils.translate('erp.product.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                setTimeout(() => {
                    window.history.back()
                }, 1000)
            }
        });
        jQuery("body").delegate(".delete_drop_row", "click", (e: any) => {
            const data = JSON.parse(e.currentTarget.closest("tr").getAttribute("data-data"));
            const dragElements = JSON.parse(localStorage.getItem("tickware_erp_product_multi_move") as string)
            const productId = e.currentTarget.getAttribute("data-product-id");
                dragElements[productId].elements = dragElements[productId].elements.filter((e) => {
                    if(e.warehousePalletId === data.warehousePalletId &&
                        e.warehouseLocationId === data.warehouseLocationId &&
                        e.customerId === data.customerId &&
                        e.serial === data.serial) {
                        return false
                    } else {
                        return true
                    }
                })
                if (dragElements[productId].elements.length === 0) {
                    delete dragElements[productId]
                }
                localStorage.setItem("tickware_erp_product_multi_move", JSON.stringify(dragElements))
            this.generateDragTable()
        })
        jQuery("body").delegate("#erp_product_process_action", "click", (e: any) => {
            const dragElements = JSON.parse(localStorage.getItem("tickware_erp_product_multi_move") as string)
            if(Object.keys(dragElements).length > 0) {
                this.multiMoveModal.show();
            }
        });

        (document.querySelector("#erp_product_multi_move_form") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            console.log("save")
            const warehouseId = (document.querySelector("#erp_product_multi_move_warehouse_id option:checked") as HTMLSelectElement)?.value;
            const warehouseLocationId = (document.querySelector("#erp_product_multi_move_warehouse_location_id option:checked") as HTMLSelectElement)?.value
            const warehousePalletId = (document.querySelector("#erp_product_multi_move_warehouse_location_pallet_id option:checked") as HTMLSelectElement)?.value
            const customerId = (document.querySelector("#erp_product_multi_move_customer_id option:checked") as HTMLSelectElement)?.value
            Utils.showLoader()
            const dragElements = JSON.parse(localStorage.getItem("tickware_erp_product_multi_move") as string)
            // @ts-ignore
            for (const productId of Object.keys(dragElements)) {
                const dragElement = dragElements[productId]
                await Utils.erp.multiMove(productId, dragElement.elements, warehouseId, warehouseLocationId, warehousePalletId, customerId)
            }
            await this.getEntity()
            this.multiMoveModal.hide()
            localStorage.setItem("tickware_erp_product_multi_move", "{}")
            this.generateDragTable()
            Utils.hideLoader()
        });

        jQuery("#erp_product_multi_move_warehouse_location_id").on("select2:select", (e: any) => {
            (document.querySelector("#erp_product_multi_move_warehouse_location_pallet_id") as HTMLSelectElement).disabled = false
        });
    }
    onSelectGetFilterOptions(elem: any) {
        if (elem.getAttribute("id") === "erp_product_multi_move_warehouse_location_id") {
            return {
                warehouse_id: (document.querySelector("#erp_product_multi_move_warehouse_id option:checked") as HTMLSelectElement)?.value
            }
        } else if (elem.getAttribute("id") === "erp_product_multi_move_warehouse_location_pallet_id") {
            return {
                warehouse_location_id: (document.querySelector("#erp_product_multi_move_warehouse_location_id option:checked") as HTMLSelectElement)?.value
            }
        }
        return super.onSelectGetFilterOptions(elem)
    }

    generateDragTable() {
        let html = ""
        const dragElements = JSON.parse(localStorage.getItem("tickware_erp_product_multi_move") as string)
        if (dragElements && Object.keys(dragElements).length > 0) {
            for (const productId of Object.keys(dragElements)) {
                const dragElement = dragElements[productId]
                html += `<b>${dragElement.name}</b>`
                html += '<table class="table table-striped"><tbody>'
                dragElement.elements.forEach((elem: any) => {
                    html += `<tr data-data='${JSON.stringify(elem)}'><td style="width: 70%">${elem.title}</td><td style="width: 30%"><button data-product-id="${productId}" class="btn btn-xs btn-danger delete_drop_row"><i class="ti ti-x"></i></button> </td></tr>`
                });
                html += '</tbody></table>';
            }

        } else {
            this.multiMoveModal.hide();
        }
        (document.querySelector("#erp_product_multi_move_modal_table") as HTMLElement).innerHTML = html;
        if (html.length > 0) {
            html += '<button class="w-100 btn btn-primary mt-2" id="erp_product_process_action">Process</button>';
        }
        (document.querySelector(".dropzone") as HTMLElement).innerHTML = html;
    }

    async updateEntity(data: any) {
        await super.updateEntity(data);
        if (data.category) {
            const $newOption = jQuery("<option selected='selected'></option>").val(data.category.uuid).text(data.category.name)
            jQuery("#erp_product_category_id").append($newOption).trigger('change')
        }
        this.generateDragTable()
    }
}