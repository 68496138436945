import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import CustomerLocationEntity from "../../../customer_location/entity";
export default class CustomerOverviewShowTickets{
    private parent: any;
    private tableElem: any;
    private datatable: any;
    private entity = "tickets";
    private toastr: any;
    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.tableElem = jQuery('.customer_show_tickets')
        this.createTable();
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {}
    }

    bindListeners() {
    }

    createTable() {
        this.datatable = new DataTable(this.tableElem, {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/customers/${this.parent.id}/tickets`,
            columns: [
                {data: 'number'},
                {data: 'title'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<a href="/${(<any>window).currentLocale}/ticket/tasks/${full.uuid}">${data}</a>`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.project) {
                            return `${full.project.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.assignee) {
                            return `${full.assignee.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 4,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.status) {
                            return `${full.status.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 5,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.priority) {
                            return `<span style="background-color:${full.priority.color};width: 100%; display: block; height: 100%; padding: 4px; text-align: center; color: #FFF;">${full.priority.name}</span>`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 6,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="/${(<any>window).currentLocale}/ticket/tasks/${data}" class="text-body">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <!--<a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>!-->
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    action: () => {
                        document.location.href = `/${(window as any).currentLocale}/ticket/tasks/new`
                    }
                }
            ]
        });
    }
}