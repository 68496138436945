export default class ErpLabelEntity {
    static getEntityData(elem: any) {
        return {
            width: parseInt((elem.querySelector("#erp_labels_width") as HTMLInputElement).value),
            height: parseInt((elem.querySelector("#erp_labels_height") as HTMLInputElement).value),
            marginLeft: parseInt((elem.querySelector("#erp_labels_marginLeft") as HTMLInputElement).value),
            marginTop: parseInt((elem.querySelector("#erp_labels_marginTop") as HTMLInputElement).value),
            paddingLeft: parseInt((elem.querySelector("#erp_labels_paddingLeft") as HTMLInputElement).value),
            paddingTop: parseInt((elem.querySelector("#erp_labels_paddingTop") as HTMLInputElement).value),
            paddingRight: parseInt((elem.querySelector("#erp_labels_paddingRight") as HTMLInputElement).value),
            paddingBottom: parseInt((elem.querySelector("#erp_labels_paddingBottom") as HTMLInputElement).value),
        }
    }
}