export default class AbsenceEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#absence_uuid") ? (elem.querySelector("#absence_uuid") as HTMLInputElement).value : null,
            from: (elem.querySelector("#absence_from") as HTMLInputElement).value,
            to: (elem.querySelector("#absence_to") as HTMLInputElement).value,
            absence_mode_id: (elem.querySelector("#absence_mode_id option:checked") as HTMLInputElement).value,
            replacement_user_id: (elem.querySelector("#absence_replacement_user_id option:checked") as HTMLInputElement) ? (elem.querySelector("#absence_replacement_user_id option:checked") as HTMLInputElement).value : null,
            user_id: elem.querySelector("#absence_user_id") ? (elem.querySelector("#absence_user_id option:checked") as HTMLInputElement).value : null,
            comment: (elem.querySelector("#absence_comment") as HTMLInputElement).value,
        }
    }
}