export default class DocumentTemplateEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#document_template_id") ? (elem.querySelector("#document_template_id") as HTMLInputElement).value : null,
            name: (elem.querySelector("#document_template_name") as HTMLInputElement).value,
            html: (elem.querySelector("#document_template_html") as HTMLInputElement) ? (elem.querySelector("#document_template_html") as HTMLInputElement).value : "",
            active: (elem.querySelector("#document_template_active") as HTMLInputElement) ? (elem.querySelector("#document_template_name") as HTMLInputElement).value : false,
            document_type: (elem.querySelector("#document_template_document_type option:checked") as HTMLInputElement).value,
        }
    }
}