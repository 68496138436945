import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'

import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import DocumentTemplateEntity from './entity';

export default class SettingsDocumentTemplatesList extends DefaultController {
    tableElem: any;
    datatable: any;
    editModal: bootstrap.Modal | undefined;
    siteInfo: any = {};

    async init() {
        this.entity = "document_templates"
        this.tableElem = jQuery('.datatables-users')
        this.createTable();
        this.editModal = new bootstrap.Modal((document.querySelector("#editDocumentTemplate") as HTMLElement));
        await super.init();
    }

    getEntityData(elem: any) {
        return DocumentTemplateEntity.getEntityData(elem)
    }

    bindListeners() {
        (document.querySelector("#document_template_html_preview_update") as HTMLTextAreaElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const target = document.querySelector("#document_template_html") as HTMLTextAreaElement;
            const previewDiv = document.querySelector("#document_template_html_preview") as HTMLElement;
            previewDiv.innerHTML = await this.parseHtml(target.value);
        });
        (document.querySelector("#editDocumentTemplateForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editDocumentTemplateForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    this.datatable.ajax.reload();
                    // @ts-ignore
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('document_template.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editDocumentTemplateForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form);

                const previewDiv = document.querySelector("#document_template_html_preview") as HTMLElement;
                previewDiv.innerHTML = await this.parseHtml(data.html);
                // @ts-ignore
                this.editModal.show();
            }
        });

        this.tableElem.delegate(".delete-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id");const confirm = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if(confirm.value === true) {
                const r = await Utils.entity.destroy(id, this.entity);
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('document_template.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('document_template.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });
        (document.querySelector("#addNewDocumentTemplateForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#addNewDocumentTemplateForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    elem.reset()
                    await this.datatable.ajax.reload();

                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddDocumentTemplate") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    this.toastr.success(`${Utils.translate('document_template.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }

    createTable() {
        this.datatable = new DataTable(this.tableElem, {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/document_templates`,
            columns: [
                {data: 'document_type'},
                {data: 'name'},
                {data: 'active'},
                {data: 'created_at'},
                {data: 'id'}
            ],
            columnDefs: [
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${data}`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (data) {
                            return `<span class="badge bg-label-primary p-2 rounded"><i class="ti ti-checkbox ti-sm"></i></span>`;
                        } else {
                            return `<span class="badge bg-label-primary p-2 rounded"><i class="ti ti-square-off ti-sm"></i></span>`;
                        }
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${new Date(Date.parse(data)).toLocaleString()}`;
                    },
                },
                {
                    targets: 4,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddDocumentTemplate'
                    }
                }
            ]
        });
    }

    async parseHtml(html: string) {
        const data = await Utils.erp.generateDocument(html)

        return data;
    }
}