export default class ErpProductEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#erp_product_id") ? (elem.querySelector("#erp_product_id") as HTMLInputElement).value : null,
            name: (elem.querySelector("#erp_product_name") as HTMLInputElement).value,
            number: (elem.querySelector("#erp_product_number") as HTMLInputElement).value,
            product_category_id: elem.querySelector("#erp_product_category_id") && elem.querySelector("#erp_product_category_id option:checked") ? (elem.querySelector("#erp_product_category_id option:checked") as HTMLInputElement).value : null,
            price_net: (elem.querySelector("#erp_product_price_net") as HTMLInputElement).value,
            price: (elem.querySelector("#erp_product_price") as HTMLInputElement).value,
            purchase_price_net: (elem.querySelector("#erp_product_purchasing_price_net") as HTMLInputElement).value,
            purchase_price: (elem.querySelector("#erp_product_purchasing_price") as HTMLInputElement).value,
            tax_id: elem.querySelector("#erp_product_taxId") ? (elem.querySelector("#erp_product_taxId option:checked") as HTMLInputElement).value : null,


            width: parseInt((elem.querySelector("#erp_product_width") as HTMLInputElement).value),
            height: parseInt((elem.querySelector("#erp_product_height") as HTMLInputElement).value),
            length: parseInt((elem.querySelector("#erp_product_length") as HTMLInputElement).value),
            weight: parseInt((elem.querySelector("#erp_product_weight") as HTMLInputElement).value),


            ean: (elem.querySelector("#erp_product_ean") as HTMLInputElement).value,
            manufacturer_id: (elem.querySelector("#erp_product_manufacturer") as HTMLInputElement).value,
            manufacturer_number: (elem.querySelector("#erp_product_manufacturerNumber") as HTMLInputElement).value,
            description: (elem.querySelector("#erp_product_description") as HTMLInputElement).value,
            requires_serial: (elem.querySelector("#erp_product_requires_serial") as HTMLInputElement).checked,
        }
    }
}