import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import ErpProductSupplierEntity from "../../../product_supplier/entity";
export default class ErpProductSupplier{
    private parent: any;
    private entity = "erp/supplier_products";
    private toastr: any;
    private datatable: any = null;
    private datatableElem: any;
    private editModal: bootstrap.Modal;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.datatableElem = jQuery(".datatables-erp-product-supplier")
        this.editModal = new bootstrap.Modal((document.querySelector("#editErpProductSupplierModal") as HTMLElement));
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...ErpProductSupplierEntity.getEntityData(elem), productId: this.parent.id}
    }

    bindListeners() {
        (document.querySelector("#addNewErpProductSupplierForm") as HTMLFormElement).addEventListener("submit", async (e: any) => {
            e.preventDefault();
            const elem = document.querySelector("#addNewErpProductSupplierForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                await Utils.showLoader();
                await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                this.toastr.success(`${Utils.translate('erp.supplier.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)


                const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddErpProductSupplier") as HTMLElement))
                if (bsElem) {
                    bsElem.hide();
                }
                await Utils.hideLoader();
                await this.parent.getEntity()
            }
        });

        (document.querySelector("#editErpProductSupplierForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editErpProductSupplierForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.parent.getEntity()
                    const bsElem = bootstrap.Modal.getInstance((document.querySelector("#editErpProductSupplierModal") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    this.toastr.success(`${Utils.translate('erp.supplier.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });


        this.datatableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editErpProductSupplierForm") as HTMLFormElement);
                Object.keys(data).forEach((key) => {
                    const elem = form.querySelector(`#erp_product_supplier_${key}`) as HTMLInputElement | null
                    if(elem && data[key]) {
                        elem.value = data[key]
                    }
                })

                const $newOption = jQuery("<option selected='selected'></option>").val(data.supplierId).text(data.supplier[0].name)
                jQuery("#editErpProductSupplierForm #erp_product_supplier_supplier_id").append($newOption).trigger('change');

                // @ts-ignore
                this.editModal.show();
            }

        });

        this.datatableElem.delegate(".delete-record", "click", async (e: any) => {
            e.preventDefault();const confirm = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id");
                const r = await Utils.entity.destroy(id, this.entity);
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('erp.supplier.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('erp.supplier.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                await this.parent.getEntity()
            }
        });
    }

    createDataTable() {
        this.datatable = new DataTable(".datatables-erp-product-supplier", {
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddErpProductSupplier'
                    }
                }
            ]
        });
    }

    async update(data: any) {
        if (this.datatable) {
            this.datatable.destroy();
        }
        await Utils.updateElements(data, '', (document.querySelector("#erp_product_supplier") as HTMLElement))
        const table = document.querySelector(".datatables-erp-product-supplier tbody") as HTMLElement;
        table.innerHTML = "";
        data.suppliers.forEach((supplier: any) => {
            const tr = `<tr>
                <td>${supplier.name}</td>
                <td>${supplier.stockNotify}</td>
                <td>${supplier.isDefault}</td>
                <td><div class="d-flex align-items-center"> 
                <a href="#" data-id="${supplier.id}" class="text-body edit-record"> <i class="ti ti-edit ti-sm me-2"></i> </a> 
                <a href="#" data-id="${supplier.id}" class="text-body delete-record"> <i class="ti ti-trash ti-sm mx-2"></i> </a> 
                </div></td>
                </tr>`
            table.innerHTML = table.innerHTML + tr;
        });
        this.createDataTable();
    }
}