export default class ErpBoxEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#erp_box_id") ? (elem.querySelector("#erp_box_id") as HTMLInputElement).value : null,
            name: (elem.querySelector("#erp_box_name") as HTMLInputElement).value,
            width: parseInt((elem.querySelector("#erp_box_width") as HTMLInputElement).value),
            height: parseInt((elem.querySelector("#erp_box_height") as HTMLInputElement).value),
            length: parseInt((elem.querySelector("#erp_box_length") as HTMLInputElement).value),
        }
    }
}