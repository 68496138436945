import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import TicketServiceMapEntity from "../../../ticket_service_map/entity";


export default class TicketShowOrders {
    private parent: any;
    private tableElem: any;
    private datatable: any;
    private entity = "services";
    private toastr: any;
    private datatableElem: any;


    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.datatableElem = jQuery(".datatables-ticket-orders")
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...TicketServiceMapEntity.getEntityData(elem), ticket_id: this.parent.id}
    }

    bindListeners() {
    }

    createDataTable() {
        this.datatable = new DataTable(".datatables-ticket-orders", {
            processing: true,
            dom:
                '<"row mb-2 w-100 align-items-center justify-content-between"' +
                '<"col-3 col-md-1 dt-info-l"<""l>>' +
                '<"col-9 col-md-2 dt-info-r"<"dt-action-buttons"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-7"i>' +
                '<"col-5 d-flex justify-content-end mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }

    async update(data: any) {
        if (this.datatable) {
            this.datatable.destroy();
        }

        const table = document.querySelector(".datatables-ticket-orders tbody") as HTMLElement;
        if (table) {
            table.innerHTML = "";
            if (data.orders) {
                data.orders.forEach((order: any) => {
                    console.log(order)
                    const tr = `<tr>
<td>${new Date(Date.parse(order.created_at)).toLocaleString()}</td>
<td>${order.number}</td>
<td>${order.status.name}</td>
<td>${order.tracking_numbers.join(", ")}</td>
<td><a href="/en/erp/orders/${order.id}" class="text-body">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a></td>
                </tr>`
                    table.innerHTML = table.innerHTML + tr;
                });
                this.createDataTable();
            }
        }

    }
}