export default class CustomerLocationEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#customer_location_uuid") ? (elem.querySelector("#customer_location_uuid") as HTMLInputElement).value : null,
            name: (elem.querySelector("#customer_location_name") as HTMLInputElement).value,
            first_name: (elem.querySelector("#customer_location_first_name") as HTMLInputElement).value,
            last_name: (elem.querySelector("#customer_location_last_name") as HTMLInputElement).value,
            street: (elem.querySelector("#customer_location_street") as HTMLInputElement).value,
            house_number: (elem.querySelector("#customer_location_house_number") as HTMLInputElement).value,
            postcode: (elem.querySelector("#customer_location_postcode") as HTMLInputElement).value,
            city: (elem.querySelector("#customer_location_city") as HTMLInputElement).value,
            phone: (elem.querySelector("#customer_location_phone") as HTMLInputElement).value,
            country: elem.querySelector("#customer_location_county_id") ? (elem.querySelector("#customer_location_county_id option:checked") as HTMLInputElement).value : (elem.querySelector("#new_customer_location_county_id option:checked") as HTMLInputElement).value,
            salutation: elem.querySelector("#customer_location_salutation_id") ? (elem.querySelector("#customer_location_salutation_id option:checked") as HTMLInputElement).value : (elem.querySelector("#new_customer_location_salutation_id option:checked") as HTMLInputElement).value
        }
    }
}