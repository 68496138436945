import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'

import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import WarehouseLocationPalletEntity from "../../warehouse_location_pallet/entity";

export default class ErpPalletList extends DefaultController {
    tableElem: any;
    datatable: any;
    editModal: bootstrap.Modal | undefined;
    stockDatatable: any = null

    async init() {
        this.entity = "erp/warehouse_location_pallets"
        this.tableElem = jQuery('.datatables-users')
        this.createTable();
        this.editModal = new bootstrap.Modal((document.querySelector("#editWarehouseLocationPallet") as HTMLElement));
        await super.init();
    }

    getEntityData(elem: any) {
        return WarehouseLocationPalletEntity.getEntityData(elem)
    }

    bindListeners() {
        (document.querySelector("#editWarehouseLocationPalletForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault()
            const form = (document.querySelector("#editWarehouseLocationPalletForm") as HTMLFormElement);

            const palletId = (form.querySelector("#erp_warehouse_location_pallet_id") as HTMLInputElement)?.value;
            const warehouseId = (form.querySelector("#warehouse_location_pallet_warehouse_id option:checked") as HTMLSelectElement)?.value;
            const warehouseLocationId = (form.querySelector("#warehouse_location_pallet_warehouse_location_id option:checked") as HTMLSelectElement)?.value;
            const palletName = (form.querySelector("#erp_warehouse_location_pallet_name") as HTMLInputElement)?.value;

            await Utils.showLoader();
            await Utils.entity.upsert({name: palletName, uuid: palletId, warehouse_location_id: warehouseLocationId}, 'erp/warehouse_location_pallets')
            form.reset()
            await Utils.hideLoader();
            this.editModal.hide();
            this.datatable.ajax.reload();
        });
        jQuery("#editWarehouseLocationPalletForm #warehouse_location_pallet_warehouse_id").on("select2:select", (e: any) => {
            jQuery("#editWarehouseLocationPalletForm #warehouse_location_pallet_warehouse_location_id").val("").trigger("change");
        });
        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editWarehouseLocationPalletForm") as HTMLFormElement);
                Object.keys(data).forEach((key) => {
                    const elem = form.querySelector(`#erp_warehouse_location_pallet_${key}`) as HTMLInputElement | null
                    if(elem && data[key]) {
                        elem.value = data[key]
                    }
                });
                const $newOptionWarehouseLocation = jQuery("<option selected='selected'></option>").val(data.warehouse_location.id).text(data.warehouse_location.name)
                jQuery("#editWarehouseLocationPalletForm #warehouse_location_pallet_warehouse_location_id").append($newOptionWarehouseLocation).trigger('change');


                const $newOptionWarehouse = jQuery("<option selected='selected'></option>").val(data.warehouse_location.warehouse.id).text(data.warehouse_location.warehouse.name)
                jQuery("#editWarehouseLocationPalletForm #warehouse_location_pallet_warehouse_id").append($newOptionWarehouse).trigger('change');

                // @ts-ignore
                this.editModal.show();
                const mapData: any = {}

                let html = '<table class="table table-striped"><thead><tr><th>Product</th><th>Serials</th></tr></thead><tbody>'
                data.stock.forEach((s: any) => {
                    html += `<tr><td>${s.product.name}</td><td>${s.serial}</td></tr>`
                })
                html += '</tbody></table>';
                (document.querySelector("#editWarehouseLocationPalletStock") as HTMLElement).innerHTML = html;
                if (this.stockDatatable) {
                    this.stockDatatable.destroy();
                }
                this.stockDatatable = new DataTable("#editWarehouseLocationPalletStock table", {
                    processing: true,
                    dom:
                        '<"row me-2 align-items-center"' +
                        '<"col-md-2"<"me-3 m-3"l>>' +
                        '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                        '>t' +
                        '<"row mx-2 align-items-center justify-content-between"' +
                        '<"col-6"i>' +
                        '<"col-6 mt-3"p>' +
                        '>',
                    language: {
                        sLengthMenu: '_MENU_',
                        search: '',
                        searchPlaceholder: `${Utils.translate('generic.search')}...`,
                        "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                        "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                        "paginate": {
                            "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                            "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                            "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                            "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                        },
                        "info":           `${Utils.translate('generic.datatable.info.info')}`,
                        "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                        "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
                    },
                    buttons: [
                        {
                            text: `${Utils.translate('generic.download')}`,
                            className: 'dt-button btn btn-primary m-2',
                            action: async () => {
                                let csv = '';
                                const csvHeader: any = []
                                this.stockDatatable.columns().header().each((column: any, i: number) => {
                                    const visible = this.stockDatatable.column(i).visible();
                                    if (visible) {
                                        let content = column.innerHTML
                                        if (content.indexOf("<span") !== -1) {
                                            content = content.match(/>(.*)<\/span><span/)[1]
                                        }
                                        csvHeader.push(content)
                                    }
                                })
                                csv += `${csvHeader.join(";")}\n`
                                this.stockDatatable.rows().data().each((data: any) => {
                                    console.log(data)
                                    if (data[1].indexOf(", ") !== -1) {
                                        data[1].split(", ").forEach((serial: string) => {
                                            let content = [data[0], serial]
                                            csv += content.join(";") + "\n";
                                        })
                                    } else {
                                        let content = [data[0], data[1]]
                                        csv += content.join(";") + "\n";
                                    }
                                })

                                await Utils.download(new Blob(["\ufeff", csv]), 'export.csv')
                            }
                        }
                    ]
                });
            }

        });
        this.tableElem.delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id");
                const r = await Utils.entity.destroy(id, this.entity);
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('erp.warehouse_location_pallet.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('erp.warehouse_location_pallet.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });

    }
    onSelectGetFilterOptions(elem: any) {
        if (elem.getAttribute("id") === "warehouse_location_pallet_warehouse_location_id") {
            return {
                warehouse_id: (document.querySelector("#warehouse_location_pallet_warehouse_id option:checked") as HTMLSelectElement)?.value
            }
        }
        return {}
    }
    createTable() {
        this.datatable = new DataTable(this.tableElem, {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: {
                url: "/api/v1/erp/warehouse_location_pallets",
                dataType: 'json',
                // @ts-ignore
                data: (params: { length: any; start: any; draw: any; order: any; search: any; }) => {
                    return {
                        limit: params.length,
                        page: params.start/25+1,
                        draw: params.draw,
                        search: params.search ? params.search.value : null,
                        sort: params.order ? params.order[0] : []
                    };
                },
                // @ts-ignore
                dataSrc: (data: any, params: any) => {
                    if(data.status === 200) {
                        data["recordsTotal"] = data.meta.total;
                        data["recordsFiltered"] = data.meta.total;
                        data["draw"] = data.meta.draw;
                    }
                    return data.data;
                },
                cache: true
            },
            serverSide: true,
            columns: [
                {data: 'name'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.warehouse_location ? full.warehouse_location.warehouse.name : "---";
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.warehouse_location ? full.warehouse_location.name : "";
                    },
                },
                {
                    targets: 3,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" class="text-body delete-record" data-id="${full.id}">
                                    <i class="ti ti-trash ti-sm me-2"></i>
                                  </a> 
                                  <a href="#" class="text-body edit-record" data-id="${full.id}">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }
}