import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import WarehouseLocationPalletPalletEntity from "../../../warehouse_location_pallet/entity";
export default class WarehouseWarehouseLocationPalletsOverview{
    private parent: any;
    private tableElem: any;
    private datatable: any;
    private entity = "erp/warehouse_location_pallets";
    private toastr: any;
    private editModal: bootstrap.Modal;
    private configurator: any;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.tableElem = jQuery('.erp_warehouse_location_pallet')
        this.editModal = new bootstrap.Modal((document.querySelector("#editWarehouseLocationPallet") as HTMLElement));
        this.createTable();
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...WarehouseLocationPalletPalletEntity.getEntityData(elem)}
    }

    bindListeners() {

        this.tableElem.delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                e.preventDefault();
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('erp.warehouse_location_pallet.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('erp.warehouse_location_pallet.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });
        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editWarehouseLocationPalletForm") as HTMLFormElement);
                form.reset()
                Object.keys(data).forEach((key) => {
                    const elem = form.querySelector(`#warehouse_location_pallet_${key}`) as HTMLInputElement | null
                    if (elem && data[key]) {
                        elem.value = data[key]
                    }
                })
                const $newOptionSalutation = jQuery("<option selected='selected'></option>").val(data.warehouse_location.id).text(data.warehouse_location.name)
                jQuery("#editWarehouseLocationPalletForm #warehouse_location_pallet_warehouse_location_id").append($newOptionSalutation).trigger('change');

                this.editModal.show();
            }
        });

        (document.querySelector("#editWarehouseLocationPalletForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editWarehouseLocationPalletForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('erp.warehouse_location_pallet.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }

    createTable() {
        this.datatable = new DataTable(this.tableElem, {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            pageLength: 25,
            serverSide: true,
            ajax: {
                url: `/api/v1/erp/warehouse_location_pallets?warehouse_id=${this.parent.id}`,
                dataType: 'json',
                // @ts-ignore
                data: (params: { length: any; start: any; draw: any; order: any; search: any; }) => {
                    console.log("aaa", params)
                    return {
                        limit: params.length,
                        page: params.start/25+1,
                        draw: params.draw,
                        search: params.search ? params.search.value : null,
                        sort: params.order ? params.order[0] : []
                    };
                },
                // @ts-ignore
                dataSrc: (data: any, params: any) => {
                    if(data.status === 200) {
                        data["recordsTotal"] = data.meta.total;
                        data["recordsFiltered"] = data.meta.total;
                        data["draw"] = data.meta.draw;
                    }
                    return data.data;
                },
                cache: true
            },
            columns: [
                {data: 'name'},
                {data: 'id'},
                {data: 'id'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        //@ts-ignore
                        return full.warehouse_location ? full.warehouse_location.name : "";
                    },
                },
                {
                    targets: 2,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm"></i>
                                  </a>
                                  <a href="#" data-id="${data}" data-entity="erp/warehouse_location_pallets" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }

    onSelectGetFilterOptions(elem: any) {
        if (elem.getAttribute("id") === "warehouse_location_pallet_warehouse_location_id") {
            return {
                warehouse_id: this.parent.id
            }
        }
        return {}
    }
}