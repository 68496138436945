
import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import TripEntity from "../../../trip/entity";

export default class TeamsTripsList extends DefaultController {
    tableElem: any;
    datatable: any;

    async init() {
        this.entity = "trips";
        this.tableElem = jQuery('.datatables-users')
        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return TripEntity.getEntityData(elem)
    }

    bindListeners() {
        this.tableElem.delegate(".accept-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const r = await Utils.entity.upsert({uuid: id, status: "ACCEPTED"}, this.entity)
            if (r.status === 200) {
                this.toastr.success(`${Utils.translate('trip.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
            } else {
                this.toastr.error(`${Utils.translate('trip.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
            }
            this.datatable.ajax.reload();
        });
        this.tableElem.delegate(".decline-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const r = await Utils.entity.upsert({uuid: id, status: "DECLINED"}, this.entity)
            if (r.status === 200) {
                this.toastr.success(`${Utils.translate('trip.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
            } else {
                this.toastr.error(`${Utils.translate('trip.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
            }
            this.datatable.ajax.reload();
        });
    }

    createTable() {
        this.datatable = new DataTable(this.tableElem, {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/trips?status_name=new`,
            columns: [
                {data: 'uuid'},
                {data: 'date'},
                {data: 'distance'},
                {data: 'transportation'},
                {data: 'uuid'},
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.user.name;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${data}km`;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 4,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="accept-record btn btn-success" style="margin-right: 5px;">
                                    <i class="ti ti-circle-check-filled ti-sm"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="decline-record btn btn-danger">
                                    <i class="ti ti-circle-x-filled ti-sm"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }
}