import UtilsEntityView from "./entityView";
import Utils from "./index";

export default class UtilsLicense {
    static async buy(productId: string, users: number) {
        await Utils.showLoader();
        let url = `/api/v1/actions/buy`
        const r = await UtilsLicense.request(url, 'POST', {
            product_id: productId,
            users
        })
        await Utils.hideLoader();
        return r;
    }


    static async request(url: string, method: string, body = {}, plaintext = false, json = true) {
        const init = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        if (json) {
            // @ts-ignore
            init.headers['Accept'] =  'application/json';
        }
        if (method !== "GET" && method !== "DELETE") {
            if (!plaintext) {
                // @ts-ignore
                init.body = JSON.stringify(body)
            } else {
                // @ts-ignore
                init.body = body
            }
        }
        const r = await fetch(url, init)
        if (json) {
            return await r.json()
        } else {
            return await r.text()
        }
    }
}