import Utils from "../../../utils";
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import DefaultController from "../../defaultController";


export default class TeamsHome extends DefaultController {
    private absenceModal: any;
    private absenceTable: any
    private tableElem: any;

    async init() {
        this.entity = "absences"
        this.tableElem = jQuery(".teams_home_absences_pending")
        this.tableElem.delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('absence.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('absence.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                await this.request("")
                console.log("this.absenceTable", this.absenceTable)
            }
        });
        this.setupEventListeners();

        await this.setupDatatables();
        await this.request("");
        this.bindListeners()
        Utils.hideLoader()
    }

    protected bindListeners() {
    }

    setupDatatables() {
        let columns = [
            {data: 'from'},
            {data: 'to'},
            {data: 'name'},
            {data: 'uuid'}
        ];
        let columnDefs = [
            {
                targets: 0,
                render: (data: any, type: any, full: any, meta: any) => {
                    return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                },
            },
            {
                targets: 1,
                render: (data: any, type: any, full: any, meta: any) => {
                    return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                },
            },
            {
                targets: 2,
                render: (data: any, type: any, full: any, meta: any) => {
                    return full.absence_mode.name
                },
            },
            {
                targets: 3,
                searchable: false,
                orderable: false,
                render: (data: any, type: any, full: any, meta: any) => {
                    if (full.status === "NEW" || (window.activeUser && window.activeUser.role === "admin")) {
                        return `<div class="d-flex align-items-center justify-content-center">
                                  <a href="javascript:void(1)" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    } else {
                        return Utils.translate(`absence.status.${full.status.toLowerCase()}`);
                    }
                },
            }
        ]
        this.absenceTable = new DataTable(this.tableElem, {
            ajax: `/api/v1/absences`,
            columns: columns,
            order: [[0, 'desc']],
            columnDefs: columnDefs,
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',

            language: {
                // @ts-ignore
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `${Utils.translate('generic.download')}`,
                    className: 'dt-button btn btn-primary m-2',
                    action: async () => {
                        let csv = '';
                        const csvHeader: any = []
                        this.absenceTable.columns().header().each((column: any, i: number) => {
                            const visible = this.absenceTable.column(i).visible();
                            if (visible) {
                                let content = column.innerHTML
                                if (content.indexOf("<span") !== -1) {
                                    content = content.match(/>(.*)<\/span><span/)[1]
                                }
                                if (content !== "Actions") {

                                }
                                csvHeader.push(content)
                            }
                        })
                        csv += `${csvHeader.join(";")}\n`
                        this.absenceTable.rows().data().each((data: any) => {
                            console.log(data)
                            let content = [new Date(Date.parse(data.from)).toLocaleDateString(),
                                new Date(Date.parse(data.to)).toLocaleDateString(),
                                data.absence_mode.name
                            ]
                            csv += content.join(";") + "\n";
                        })

                        await Utils.download(new Blob(["\ufeff", csv]), 'export.csv')
                    }
                }
            ]
        });
    }

    async setupEventListeners() {
        const prev = document.querySelector("#teams_home_absence_prev") as HTMLElement;
        const next = document.querySelector("#teams_home_absence_next") as HTMLElement;
        prev.addEventListener("click", async (e: any) => {
            e.preventDefault();
            await this.request("minus")
        });

        next.addEventListener("click", async (e: any) => {
            e.preventDefault();
            await this.request("plus")
        })

    }

    async request(mode: string) {
        const table = document.querySelector("#teams_home_absence_table") as HTMLElement
        const yearDisplay = (document.querySelector("#teams_home_absence_date") as HTMLButtonElement);
        // @ts-ignore
        const currentDate = parseInt(yearDisplay.getAttribute("data-date"));
        let newDate = currentDate
        if (mode === "minus") {
            newDate = currentDate - 1
        }
        if (mode === "plus") {
            newDate = currentDate + 1
        }
        const r = await Utils.entity.request(`/api/v1/absences/info?year=${newDate}`, 'GET')
        if (r.status === 200) {

            yearDisplay.innerHTML = `${newDate}`;
            yearDisplay.setAttribute("data-date", `${newDate}`);
            table.innerHTML = `<tr>
                  <td><b>${Utils.translate('absence.vacation_days')}</b></td>
                  <td>${r.data.allowance}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.pending_days')}</b></td>
                  <td>${r.data.pending}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.approved_days')}</b></td>
                  <td>${r.data.taken}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.left_days')}</b></td>
                  <td>${r.data.left}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.over_hours')}</b></td>
                  <td>${r.data.over_hours}</td>
                </tr>`
            if (this.absenceTable) {
                this.absenceTable.clear();
                this.absenceTable.rows.add(r.data.absences);
                this.absenceTable.draw();
            }
        }
    }
}