export default class ErpSupplierEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#erp_supplier_id") ? (elem.querySelector("#erp_supplier_id") as HTMLInputElement).value : null,
            
            name: (elem.querySelector("#erp_supplier_name") as HTMLInputElement).value,
            street: (elem.querySelector("#erp_supplier_street") as HTMLInputElement).value,
            streetNumber: (elem.querySelector("#erp_supplier_streetNumber") as HTMLInputElement).value,
            streetAdditional: (elem.querySelector("#erp_supplier_streetAdditional") as HTMLInputElement).value,
            postcode: (elem.querySelector("#erp_supplier_postcode") as HTMLInputElement).value,
            city: (elem.querySelector("#erp_supplier_city") as HTMLInputElement).value,
            email: (elem.querySelector("#erp_supplier_email") as HTMLInputElement).value,
            telephone: (elem.querySelector("#erp_supplier_telephone") as HTMLInputElement).value,
            fax: (elem.querySelector("#erp_supplier_fax") as HTMLInputElement).value,

            customerNumber: (elem.querySelector("#erp_supplier_customerNumber") as HTMLInputElement).value,
            representative: (elem.querySelector("#erp_supplier_representative") as HTMLInputElement).value,
            emailOrder: (elem.querySelector("#erp_supplier_emailOrder") as HTMLInputElement).checked,
        }
    }
}