import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Swal from 'sweetalert2';
import Utils from '../../../../../utils'
import ErpShipmentEntity from "../../../shipment/entity";
export default class ErpOrderShipment{
    private parent: any;
    private entity = "erp/shipments";
    private toastr: any;
    private datatableElem: any;
    private datatable: any;
    private packages: any = {};

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.datatableElem = jQuery(".datatables-erp-order-shipment")
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...ErpShipmentEntity.getEntityData(elem), order_id: this.parent.id}
    }

    bindListeners() {

        jQuery("#packageAccordionChilds").delegate(".package_setting_changer", "keyup change", (e: any) => {
            e.preventDefault();
            const target = e.currentTarget;
            const inputType = target.getAttribute("type");
            let value = target.value;
            if (inputType === "number") {
                const max = target.getAttribute("max");
                if (parseFloat(value) > parseFloat(max)) {
                    target.value = max;
                }
            }
        });

        jQuery("#packageAccordionChilds").delegate(".package_setting_changer", "change", (e: any) => {
           e.preventDefault();
           const target = e.currentTarget;
            const packageId = target.getAttribute("data-package-id")
            const setting = target.getAttribute("data-setting");
            const inputType = target.getAttribute("type");
            let value = target.value;
            if (inputType === "checkbox") {
                value = target.checked;
            }
            if (setting === "weight") {
                this.packages[packageId].weight = value;
            } else {
                if (!this.packages[packageId].configured[setting]) {
                    this.packages[packageId].configured[setting] = value;
                }
            }
           console.log(this.packages);
        });

        (document.querySelector("#addErpOrderShipmentForm") as HTMLFormElement).addEventListener("submit", async (e: any) => {
            e.preventDefault();
            const elem = document.querySelector("#addErpOrderShipmentForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                await Utils.showLoader();
                const r = await Utils.entity.upsert({packages: this.packages, ...this.getEntityData(elem)}, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('erp.shipment.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                    const bsElem = bootstrap.Modal.getOrCreateInstance((document.querySelector("#modalAddErpOrderShipment") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    jQuery("#erp_shipment_shipment_company").val("").trigger("change");
                    await this.parent.getEntity()
                } else {
                    const errors = [];
                    this.toastr.error(`${Utils.translate('erp.shipment.name')} ${errors.join(", ")}`, `${Utils.translate('generic.error')}`)

                }
                await Utils.hideLoader();
            }
        });


        jQuery("#erp_shipment_shipment_company").on("select2:select", (e: any) => {
            const data = e.params.data.data;
            (document.querySelector("#addErpOrderShipmentForm") as HTMLFormElement).querySelector("button.data-submit")?.removeAttribute("disabled");
            this.generatePackages(data);

            const infoRow = document.querySelector("#erp_shipment_packages_config") as HTMLElement;
            let infoHtml = `<table class="table table-striped mt-3"><tbody>`;
            infoHtml += `<tr><td><b>Max weight</b></td><td>${data.max_weight}kg</td></tr>`
            infoHtml += `</tbody></table>`
            infoRow.innerHTML = infoHtml;
        });
        jQuery("#erp_shipment_shipment_type_id").on("select2:select", (e: any) => {
            (document.querySelector("#erp_shipment_shipment_type_name") as HTMLInputElement).value = e.params.data.data.technicalName;
        });


        jQuery(".datatables-erp-order-shipment tbody").delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id");
                const r = await Utils.entity.destroy(id, this.entity);
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('erp.shipment.name.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('erp.shipment.name.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                await this.parent.getEntity()
            }
        });
    }


    createDataTable() {
        this.datatable = new DataTable(".datatables-erp-order-shipment", {
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'modal',
                        'data-bs-target': '#modalAddErpOrderShipment'
                    },
                    action: () => {
                        (document.querySelector("#addErpOrderShipmentForm") as HTMLFormElement).querySelector("button.data-submit")?.setAttribute("disabled", "disabled");
                        this.generatePackages();
                    }
                }
            ]
        });
    }

    generatePackages(carrierData = {max_weight: 9999, config: []}) {
        console.log(carrierData)
        const maxWeight = carrierData.max_weight
        let currentPackage = {price: 0, weight: 0, max_weight: carrierData.max_weight, items: [], settings: carrierData.config, configured: {}}
        const packages: any = [];
        this.parent.data.lineItems.forEach((lineItem: any, k: number) => {
            const product = lineItem.product[0]
            for(let i = 0;lineItem.quantity > i;i++) {
                if (currentPackage.weight + product.weight > maxWeight) {
                    packages.push(currentPackage);
                    currentPackage = {weight: 0, max_weight: carrierData.max_weight, items: [], settings: carrierData.config, configured: {}, price: 0}
                }
                const payload = {
                    map_id: lineItem.payload.kind2WarehouseLocationMappingIds[i],
                    serial: lineItem.payload.kind2WarehouseLocationMappingSerials[i][0]
                }
                console.log("lineItem", lineItem)
                // @ts-ignore
                currentPackage.items.push({
                    quantity: 1,
                    lineItemId: lineItem.id,
                    productId: product.id,
                    name: lineItem.label,
                    payload: payload,
                    price: product.price[0].net
                })
                currentPackage.weight += product.weight;
                // @ts-ignore
                currentPackage.price = currentPackage.items.map(i => i.price).reduce((partialSum, a) => partialSum + a, 0)
            }
            if (!this.parent.data.lineItems[k+1]) {
                packages.push(currentPackage);
            }
        });
        
        this.packages = packages;
        const html = this.generatePackageHtml();
        (document.querySelector("#packageAccordionChilds") as HTMLElement).innerHTML = html;
    }

    generatePackageHtml() {
        let html = '';
        this.packages.forEach((pkg: any, i: number) => {
            console.log(pkg)
            let items = '';
            let colSettings = '';
            let col = "col-12";
            pkg.settings.forEach((setting: any) => {
                let value = "";
                if (setting.item_value && pkg[setting.item_value]) {
                    value = pkg[setting.item_value];
                }
                colSettings += `<div class="${col}">`
                if (setting.kind === "boolean") {
                    colSettings += `<div class="form-check form-switch">
                      <input class="form-check-input package_setting_changer" data-package-id="${i}" data-setting="${setting.key}" type="checkbox" name="package_${i}_setting${setting.key}" id="package_${i}_setting${setting.key}">
                      <label class="form-check-label" for="package_${i}_setting${setting.key}">${setting.name}</label>
                    </div>`
                } else if(setting.kind === "number") {
                    colSettings += `<label class="form-label" for="package_${i}_setting${setting.key}">${setting.name}</label>
                      <div class="input-group mb-3"><input
                        type="number"
                        value="${value}"
                        class="form-control package_setting_changer"
                        data-package-id="${i}" data-setting="${setting.key}"
                        id="package_${i}_setting${setting.key}"
                        name="package_${i}_setting${setting.key}" /><span class="input-group-text">€</span></div>`
                }
                colSettings += `</div>`
            });
            colSettings += `<div class="col-12">
                            <label class="form-label" for="package_0_settingadditionalInsurance">Weight</label>
                      <div class="input-group mb-3">
                      <input type="number" step="0.1" max="${pkg.max_weight}" value="${pkg.weight}" class="form-control package_setting_changer" data-package-id="${i}" data-setting="weight" id="package_0_setting_weight" name="package_0_setting_weight"><span class="input-group-text">kg</span>
                      </div></div>`

            pkg.items.forEach((item: any) => {
                items += `<tr><td>${item.name}</td><td>${item.payload.serial}</td></tr>`
            })
            html += `<div class="accordion-item open">
                <h2 class="accordion-header">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#packageRow${i}" aria-expanded="false" aria-controls="packageRowNew" style="background-color: #F4F4F4;">
                   Package ${i+1}
                  </button>
                </h2>
                <div id="packageRow${i}" class="accordion-collapse collapse show" data-bs-parent="#packageAccordion" style="">
                  <div class="accordion-body">
                    <div class="row align-items-center">
                    <div class="col-8">
                     <table class="table table-striped">
                            <thead>
                            <tr>
                            <th>Product</th>
                            <th>Serial</th>
                            </tr>
                            </thead>
                            <tbody>${items}</tbody>
                        </table>
</div>
                       
                    <div class="col-4">
                        <div class="row align-items-center">
                        ${colSettings}
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>`
        })
        return html;
    }

    async update(data: any) {
        if (this.datatable) {
            this.datatable.destroy();
        }

        await Utils.updateElements(data, '', (document.querySelector("#erp_order_shipment") as HTMLElement))
        const table = document.querySelector(".datatables-erp-order-shipment tbody") as HTMLElement;
        table.innerHTML = "";
        data.shipments.forEach((shipment: any) => {
            const tr = `<tr>
                <td>${new Date(Date.parse(shipment.created_at)).toLocaleString()}</td>
                <td>${shipment.shipment_company}</td>
                <td>${shipment.tracking_number}</td>
                <td><div class="d-flex align-items-center"> 
                <a download="label_${shipment.tracking_number}.pdf"  class="text-body download-record" target="_blank" href="data:application/pdf;base64,${shipment.label}"><i class="ti ti-download ti-sm me-2"></i> </a>
                <a href="#" data-id="${shipment.uuid}" class="text-body delete-record"> <i class="ti ti-trash ti-sm mx-2"></i> </a>
                </div></td>
                </tr>`
            table.innerHTML = table.innerHTML + tr;
        });
        this.createDataTable();
    }
}