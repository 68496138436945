import UtilsEntityView from "./entityView";
import Utils from "./index";

export default class UtilsErp {

    static async multiMove(productId: string, elements: any, warehouseId: string, warehouseLocationId: string, warehousePalletId: string, customerId: string) {

        let url = `/api/v1/erp/actions/multi_move_products`
        const r = await UtilsErp.request(url, "POST", {
            productId, elements, warehouseId, warehouseLocationId, warehousePalletId, customerId
        }, false, false)
        return r
    }

    static async generateDocument(html: string) {
        let url = `/erp/documents/generate_document`
        const r = await UtilsErp.request(url, "POST", {
            html
        }, false, false)
        return r
    }

    static async generateDocumentWarehouse(itemConfig: any) {
        let url = `/api/v1/erp/actions/warehouse_by_document_config`
        const r = await UtilsErp.request(url, "POST", {
            item_config: itemConfig
        })
        return r
    }

    static async checkSerial(customerId: string, productId: string, serial: string) {
        let url = `/api/v1/erp/actions/check_serial`
        const r = await UtilsErp.request(url, "POST", {
            customerId, productId, serial
        })
        return r
    }


    static async productMappingsBySerial(customerId: string, productId: string, serial: string) {
        let url = `/api/v1/erp/actions/product_mappings_by_serial`
        const r = await UtilsErp.request(url, "POST", {
            productId, serial, customerId
        })
        return r
    }
    static async productMappingsFull() {
        let url = `/api/v1/erp/actions/full_stock`
        const r = await UtilsErp.request(url, "POST", {})
        return r
    }

    static async productMappingsByWarehouse(customerId: string, productId: string, warehouseId: string, warehouseLocationId: string, warehouseLocationPalletId: string) {
        let url = `/api/v1/erp/actions/product_mappings_by_warehouse`
        const r = await UtilsErp.request(url, "POST", {
            productId, warehouseId, warehouseLocationId, warehouseLocationPalletId, customerId
        })
        return r
    }

    static async stockInfo(productId: string) {
        let url = `/api/v1/erp/actions/stock_info`
        const r = await UtilsErp.request(url, "POST", {
            productId
        })
        return r
    }
    static async productMapById(productId: string, warehouseLocationId: string, warehouseLocationPalletId: string = "", customerId: string = "") {
        let url = `/api/v1/erp/actions/product_map_by_id`
        const r = await UtilsErp.request(url, "POST", {
            productId, customerId, warehouseLocationId, warehouseLocationPalletId
        })
        return r
    }
    static async addProducts(data: any) {
        let url = `/api/v1/erp/actions/add_products`
        const r = await UtilsErp.request(url, "POST", {
            ...data
        })
        return r
    }
    static async moveProducts(data: any) {
        let url = `/api/v1/erp/actions/products_move`
        const r = await UtilsErp.request(url, "POST", {
            ...data
        })
        return r
    }
    static async outputProducts(data: any) {
        let url = `/api/v1/erp/actions/products_output`
        const r = await UtilsErp.request(url, "POST", {
            ...data
        })
        return r
    }
    static async inputProducts(data: any) {
        let url = `/api/v1/erp/actions/products_input`
        const r = await UtilsErp.request(url, "POST", {
            ...data
        })
        return r
    }

    static async movePallet(palletId: any, warehouseId: any, warehouseLocationId: any) {
        let url = `/api/v1/erp/actions/move_pallet`
        const r = await UtilsErp.request(url, "POST", {
            palletId, warehouseId, warehouseLocationId
        })
    }
    static async productStock(customerId: string, productId: string) {
        let url = `/api/v1/erp/actions/product_stock?customer_id=${customerId}&product_id=${productId}`
        const r = await UtilsErp.request(url, "GET")
        return r;
    }
    static async initOrder() {
        let url = `/api/v1/erp/actions/order_init`
        const r = await UtilsErp.request(url, "POST")
        return r;
    }

    static async saveOrder(token: any) {
        let url = `/api/v1/erp/actions/order_save`
        const r = await UtilsErp.request(url, "POST", {
            token: token
        })
        return r;
    }

    static async customerOrder(customerId: any, addressId: any, token: any) {
        let url = `/api/v1/erp/actions/order_customer`
        const r = await UtilsErp.request(url, "POST", {
            customerId: customerId,
            addressId: addressId,
            token: token
        })
    }

    static async addPalletOrder(data: any, orderId = null) {
        let url = `/api/v1/erp/actions/order_add_pallet`
        const r = await UtilsErp.request(url, "POST", {
            warehouse_pallet_id: data,
            order_id: orderId
        })
        return r;
    }
    static async addLineItemOrder(data: any, customerId: any, token: any) {
        let url = `/api/v1/erp/actions/order_add_line_item`
        const r = await UtilsErp.request(url, "POST", {
            lineItem: data,
            token: token,
            customerId
        })
        return r;
    }
    static async removeLineItemOrder(data: any, token: any) {
        let url = `/api/v1/erp/actions/order_remove_line_item`
        const r = await UtilsErp.request(url, "POST", {
            lineItem: data,
            token: token
        })
        return r;
    }

    static async downloadLabel(id: any, entity: string) {
        await Utils.showLoader();
        let url = `/api/v1/erp/actions/create_labels`
        const r = await UtilsErp.request(url, 'POST', {
            entity: entity,
            ids: [id]
        })
        await Utils.hideLoader();
        return r;
    }

    static async saveSignature(id: string, documentUrl: string, image: string) {
        await Utils.showLoader();
        let url = `/api/v1/erp/actions/save_signature`
        const r = await UtilsErp.request(url, 'POST', {
            documentId: id,
            documentUrl,
            image
        })
        await Utils.hideLoader();
        return r;
    }


    static async request(url: string, method: string, body = {}, plaintext = false, json = true) {
        const init = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        if (json) {
            // @ts-ignore
            init.headers['Accept'] =  'application/json';
        }
        if (method !== "GET" && method !== "DELETE") {
            if (!plaintext) {
                // @ts-ignore
                init.body = JSON.stringify(body)
            } else {
                // @ts-ignore
                init.body = body
            }
        }
        const r = await fetch(url, init)
        if (json) {
            return await r.json()
        } else {
            return await r.text()
        }
    }
}