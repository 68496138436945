export default class ErpOrderEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#erp_order_id") ? (elem.querySelector("#erp_order_id") as HTMLInputElement).value : null,
            status_id: (elem.querySelector("#erp_order_status_id option:checked") as HTMLInputElement).value,
            delivery_status_id: (elem.querySelector("#erp_order_delivery_status_id option:checked") as HTMLInputElement).value,
            customer_id: (elem.querySelector("#erp_order_customer_id option:checked") as HTMLInputElement) ? (elem.querySelector("#erp_order_customer_id option:checked") as HTMLInputElement).value : elem.querySelector("#erp_order_customer_id").value,
            customer_location: {
                company: elem.querySelector("#erp_order_deliveryAddress_company").value || null,
                first_name: elem.querySelector("#erp_order_deliveryAddress_first_name").value || null,
                last_name: elem.querySelector("#erp_order_deliveryAddress_last_name").value || null,
                street: elem.querySelector("#erp_order_deliveryAddress_street").value || null,
                house_number: elem.querySelector("#erp_order_deliveryAddress_house_number").value || null,
                postcode: elem.querySelector("#erp_order_deliveryAddress_postcode").value || null,
                city: elem.querySelector("#erp_order_deliveryAddress_city").value || null,
                country: (elem.querySelector("#erp_order_deliveryAddress_country_id option:checked") as HTMLInputElement).value,
            },
            ticket_id:  elem.querySelector("#erp_order_ticket_uuid option:checked") ? elem.querySelector("#erp_order_ticket_uuid option:checked").value : null,
            external_ticket: elem.querySelector("#erp_order_external_ticket_name")?.value
        }
    }
}