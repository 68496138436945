import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'

import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpDocumentEntity from "../entity";
import flatpickr from "flatpickr";

export default class ErpDocumentList extends DefaultController {
    tableElem: any;
    datatable: any;
    editModal: bootstrap.Modal | undefined;

    async init() {
        this.entity = "erp/documents"
        this.tableElem = jQuery('.datatables-users')
        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return ErpDocumentEntity.getEntityData(elem)
    }

    bindListeners() {
        jQuery(".datatables-users").delegate(".doc_row_download", "change", (e: any) => {
            const downloadAllButton = document.querySelector("#documents_download_all") as HTMLButtonElement;
            const currentChecked = Array.from(document.querySelectorAll(".doc_row_download")).map(c => c.checked);
            if (currentChecked.filter(c => c).length > 0) {
                downloadAllButton.removeAttribute("disabled")
            } else {
                downloadAllButton.setAttribute("disabled", "disabled")
            }
        });
        (document.querySelector("#documents_download_all") as HTMLButtonElement).addEventListener("click", (e) => {
            e.preventDefault();
            const urls = Array.from(document.querySelectorAll(".doc_row_download")).filter(c => c.checked).map(c => c.getAttribute("data-permalink"));
            urls.forEach((url: string) => {
                window.open(url, '_blank')
            })
        })
    }

    createTable() {
        this.datatable = new DataTable(this.tableElem, {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")


                setTimeout(() => {
                    this.datatable.columns().every((col: any) => {
                        if (col === 2 || col === 3) {
                            let filterElem: any = null
                            const column = this.datatable.column(col);
                            const header = column.header();
                            if (col === 2) {
                                filterElem = document.createElement('select');
                                filterElem.multiple = true;
                                filterElem.classList.add("form-select")
                            } else {
                                filterElem = document.createElement("input")
                                filterElem.classList.add("form-control")
                                flatpickr(filterElem, {
                                    mode: 'range',
                                    onChange: (selectedDates: any, dateStr: any, instance: any) => {
                                        let from = new Date(selectedDates[0]);
                                        let to = new Date(selectedDates[1]);
                                        let values = []
                                        if (!to) {
                                            to = new Date(Date.parse("2025-12-31"))
                                        }
                                        for (let d = from; d <= to; d.setDate(d.getDate() + 1)) {
                                            values.push(new Date(d).toLocaleDateString("de-DE", {month: "2-digit", year: "numeric", day: "2-digit"}));
                                        }
                                        console.log("values", values, values.join("|").length > 0 ? '^' + values.join("|") + '$' : '')
                                        column.search(values.join("|").length > 0 ? '' + values.join("|") + '$' : '', true, false).draw();
                                    },
                                    showMonths: 1
                                });

                            }
                            header.appendChild(filterElem);
                            filterElem.addEventListener("click", (e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                            })
                            filterElem.style.marginTop = "5px"
                            header.appendChild(filterElem);

                            const options: any = {}
                            options[col] = []

                            this.datatable.data().unique().sort().each((d: any, j: any) => {
                                if (col === 2 && d.order && d.order.customer) {
                                    options[col].push(d.order.customer.name);
                                }
                            });
                            Object.keys(options).forEach((key: any) => {
                                const v = options[key].filter((value: any, index: any, array: string | any[]) => array.indexOf(value) === index);
                                v.forEach((value: any) => {
                                    filterElem.add(new Option(value, value));
                                })
                            })
                            if (filterElem.nodeName === "SELECT") {
                                jQuery(filterElem).select2().on('select2:select select2:unselect', (e: any) => {
                                    //@ts-ignore
                                    const values = Array.from(filterElem.querySelectorAll("option:checked")).map((e) => {
                                        return Utils.escapeRegex(e.value)
                                    })
                                    column.search(values.join("|").length > 0 ? '^' + values.join("|") + '$' : '', true, false).draw();
                                });
                            }
                        }
                    });
                }, 10);

            },
            ajax: `/api/v1/erp/documents`,
            columns: [
                {data: 'uuid'},
                {data: 'number'},
                {data: 'order.customer.name'},
                {data: 'uuid'},
                {data: 'uuid'},
            ],
            select: {
                style: 'multi'
            },
            columnDefs: [
                {
                    targets: 0,
                    searchable: false,
                    orderable: false,
                    render: function (data: any, type: any, full: any) {
                        return `<input data-id="${data}" data-permalink="${full.url}" data-name="${btoa(`document_${full.number}.pdf`)}" type="checkbox" class="dt-checkboxes form-check-input doc_row_download">`;
                    },
                    checkboxes: {
                        selectRow: true,
                        selectAllRender: '<input type="checkbox" class="form-check-input">'
                    }
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${data}`;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display:none">${full.created_at}</span>${new Date(Date.parse(`${full.created_at}`)).toLocaleDateString("de-DE", {month: "2-digit", year: "numeric", day: "2-digit"})}`;

                    },
                },
                {
                    targets: 4,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                <a href="${full.url}" target="_blank" class="text-body download-record"> <i class="ti ti-download ti-sm me-2"></i> </a> 
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }
}