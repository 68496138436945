import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import ErpProductEntity from "../../entity";
export default class ErpProductGeneral{
    private parent: any;
    private entity = "erp/product";
    private toastr: any;

    private currentTaxMultiplier = 1.00;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...ErpProductEntity.getEntityData(elem), id: this.parent.id}
    }

    bindListeners() {

        jQuery("#erp_product_taxId").on("select2:select", (e: any) => {
            let r = e.params.data.data.rate
            if (`${r}`.length <= 1) {
                r = `0${r}`
            }
            this.currentTaxMultiplier = parseFloat(`1.${r}`);

            const priceNetElem = (document.querySelector("#erp_product_price_net") as HTMLInputElement);
            // @ts-ignore
            (document.querySelector("#erp_product_price") as HTMLInputElement).value = `${(parseFloat(priceNetElem.value || 0) * this.currentTaxMultiplier).toFixed(2)}`;
            const purchasePriceNetElem = (document.querySelector("#erp_product_purchasing_price_net") as HTMLInputElement);
            // @ts-ignore
            (document.querySelector("#erp_product_purchasing_price") as HTMLInputElement).value = `${(parseFloat(purchasePriceNetElem.value || 0) * this.currentTaxMultiplier).toFixed(2)}`;
        });

        (document.querySelector("#erp_product_price_net") as HTMLInputElement).addEventListener("keyup", (e) => {
            const elem = (document.querySelector("#erp_product_price_net") as HTMLInputElement);
            let value = (elem.value || "0");
            if (value.indexOf(",") !== -1) {
                value = value.replace(",", ".");
                elem.value = value;
            }
            const v = parseFloat(value);
            (document.querySelector("#erp_product_price") as HTMLInputElement).value = `${(v * this.currentTaxMultiplier).toFixed(2)}`;
        });
        (document.querySelector("#erp_product_price") as HTMLInputElement).addEventListener("keyup", (e) => {
            const elem = (document.querySelector("#erp_product_price") as HTMLInputElement);
            let value = (elem.value || "0");
            if (value.indexOf(",") !== -1) {
                value = value.replace(",", ".");
                elem.value = value;
            }
            const v = parseFloat(value);
            (document.querySelector("#erp_product_price_net") as HTMLInputElement).value = `${(v / this.currentTaxMultiplier).toFixed(2)}`;
        });


        (document.querySelector("#erp_product_purchasing_price_net") as HTMLInputElement).addEventListener("keyup", (e) => {
            const elem = (document.querySelector("#erp_product_purchasing_price_net") as HTMLInputElement);
            let value = (elem.value || "0");
            if (value.indexOf(",") !== -1) {
                value = value.replace(",", ".");
                elem.value = value;
            }
            const v = parseFloat(value);
            (document.querySelector("#erp_product_purchasing_price") as HTMLInputElement).value = `${(v * this.currentTaxMultiplier).toFixed(2)}`;
        });
        (document.querySelector("#erp_product_purchasing_price") as HTMLInputElement).addEventListener("keyup", (e) => {
            const elem = (document.querySelector("#erp_product_purchasing_price") as HTMLInputElement);
            let value = (elem.value || "0");
            if (value.indexOf(",") !== -1) {
                value = value.replace(",", ".");
                elem.value = value;
            }
            const v = parseFloat(value);
            (document.querySelector("#erp_product_purchasing_price_net") as HTMLInputElement).value = `${(v / this.currentTaxMultiplier).toFixed(2)}`;
        });
    }

    async update(data: any) {
        await Utils.updateElements(data, '', (document.querySelector("#erp_product_general") as HTMLElement))
        if (data.tax_rate) {
            let r = data.tax_rate.rate
            if (`${r}`.length <= 1) {
                r = `0${r}`
            }
            this.currentTaxMultiplier = parseFloat(`1.${r}`);
        }
        const inputTextareaSerial = (document.querySelector("#erp_product_erp_input_serials") as HTMLInputElement)
        //@ts-ignore
        const label = inputTextareaSerial.closest("div.col-12").querySelector("label span")
        if (data.requires_serial) {
            inputTextareaSerial.setAttribute("required", "required")
            //@ts-ignore
            label.innerHTML = `${Utils.translate('erp.warehouse_location.attributes.serials')}*`
        } else {
            inputTextareaSerial.removeAttribute("required")
            //@ts-ignore
            label.innerHTML = `${Utils.translate('erp.warehouse_location.attributes.serials')}`
        }
    }
}