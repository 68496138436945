import jQuery from 'jquery/dist/jquery';
import * as pdfjsLib from "pdfjs-dist"
import SignaturePad from "signature_pad";
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import TicketServiceMapEntity from "../../../ticket_service_map/entity";

export default class TicketShowDocuments {
    private parent: any;
    private tableElem: any;
    private datatable: any;
    private entity = "services";
    private toastr: any;
    private datatableElem: any;

    private pdfDocumentId = "";
    private pdfDownloadUrl = "";
    private pdfSignModal: bootstrap.Modal;
    private pdfModal: bootstrap.Modal;
    private signaturePad: SignaturePad | undefined;


    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.datatableElem = jQuery(".datatables-ticket-order-document")
        this.pdfModal = new bootstrap.Modal((document.querySelector("#erpOrderDocumentPdfModal") as HTMLElement));
        this.pdfSignModal = new bootstrap.Modal((document.querySelector("#erpOrderDocumentPdfSignModal") as HTMLElement));
        this.setupPdfReader()
        this.bindListeners();
    }


    async setupPdfReader() {
        pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
    }

    async setupSigning() {
        const canvas = document.querySelector('#erpOrderDocumentPdfSignCanvas') as HTMLCanvasElement | null;
        if (canvas) {
            if (!this.signaturePad) {
                this.signaturePad = new SignaturePad(canvas);
            } else {
                this.signaturePad.clear();
            }
        }
    }

    async saveSignature() {
        if (this.signaturePad) {
            const image = this.signaturePad.toDataURL();
            await Utils.erp.saveSignature(this.pdfDocumentId, this.pdfDownloadUrl, image)
        }
    }

    async renderPdfPage(page) {
        console.log("Render", page)
        const canvas = document.createElement("canvas")
        const scale = 1.5;
        const viewport = page.getViewport({scale: scale});
        const context = canvas.getContext('2d');
        if (context) {
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            const renderTask = page.render(renderContext);
            await renderTask.promise
        }
        canvas.style.maxWidth = "100%"
        return canvas
    }

    async loadPdf(downloadUrl: string) {
        pdfjsLib.getDocument(`${document.location.origin}/${downloadUrl}`).promise.then(async (pdf) => {
            const canvasHolder = document.querySelector('#erpOrderDocumentPdfCanvases') as HTMLCanvasElement | null;
            if (canvasHolder) {
                for (let i = 1; i <= pdf.numPages; i++) {
                    const c = await this.renderPdfPage((await pdf.getPage(i)))
                    canvasHolder.appendChild(c)
                }
            }
            this.pdfModal.show();
            const button = document.querySelector('#erpOrderDocumentPdfModalSignButton') as HTMLButtonElement;

            button.style.bottom  = `${window.siteConfig.document.y}px`
            button.style.right = `${window.siteConfig.document.x / 10}px`;
            button.style.width =`${window.siteConfig.document.width/ 3}px`;
            button.style.height = `${window.siteConfig.document.height / 3}px`;
        })
    }

    getEntityData(elem: any) {
        return {...TicketServiceMapEntity.getEntityData(elem), ticket_id: this.parent.id}
    }

    bindListeners() {

        jQuery(".datatables-ticket-order-document tbody").delegate(".sign-record", "click", async (e: JQuery.TriggeredEvent) => {
            this.pdfDownloadUrl = e.currentTarget.getAttribute("data-url");
            this.pdfDocumentId = e.currentTarget.getAttribute("data-document-id");

            /*await this.setupSigning();
            this.pdfSignModal.show();*/
            await this.loadPdf(this.pdfDownloadUrl);

        });
        (document.querySelector('#erpOrderDocumentPdfModalSignButton') as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            await this.setupSigning();
            this.pdfSignModal.show();
        });
        (document.querySelector('#erpOrderDocumentPdfSignSave') as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            await this.saveSignature();
            this.pdfSignModal.hide();
            this.pdfModal.hide();
            await this.parent.getEntity();
        });
    }

    createDataTable() {
        this.datatable = new DataTable(".datatables-ticket-order-document", {
            processing: true,
            dom:
                '<"row mb-2 w-100 align-items-center justify-content-between"' +
                '<"col-3 col-md-1 dt-info-l"<""l>>' +
                '<"col-9 col-md-2 dt-info-r"<"dt-action-buttons"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-7"i>' +
                '<"col-5 d-flex justify-content-end mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ],
            layout: {
                bottomEnd: {
                    paging: {
                        firstLast: false
                    }
                }
            }
        });
    }

    async update(data: any) {
        if (this.datatable) {
            this.datatable.destroy();
        }
        const table = document.querySelector(".datatables-ticket-order-document tbody") as HTMLElement;
        table.innerHTML = "";
        if (data.orders) {
            data.orders.forEach((order: any) => {
                order.documents.forEach((document: any) => {
                    let tr = `<tr>
                <td>${new Date(Date.parse(document.created_at)).toLocaleString()} ${document.signed ? `<b>(Signiert)</b>` : ``}</td>
                <td>${document.number}</td>
                <td>${Utils.translate(`erp.document.types.${document.document_type}`)}</td>
                <td><div class="d-flex align-items-center"> 
                
                <a href="${document.url}" target="_blank" class="text-body download-record"> <i class="ti ti-download ti-sm me-2"></i> </a> `
                if (!document.signed) {
                    tr += `<a href="#" data-document-id="${document.id}" data-url="${document.url}" class="text-body sign-record"> <i class="ti ti-writing-sign ti-sm me-2"></i> </a>`
                }
                tr += `</div></td></tr>`
                table.innerHTML = table.innerHTML + tr;
            })
            });
            this.createDataTable();
        }
    }
}