
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpOrderEntity from "../entity";
import ErpOrderGeneral from "./show/general";
import ErpOrderAddress from "./show/address";
import ErpOrderDocument from "./show/document";
import UtilsErp from "../../../../utils/erp";
import Tagify from '@yaireo/tagify';
import {createElement} from "flatpickr/dist/utils/dom";
import ErpOrderShipment from "./show/shipment";
import ErpOrderWMS from "./show/wms";
export default class ErpOrderShow extends DefaultController {
    async init() {
        this.entity = "erp/orders"
        this.childs = {
            general: new ErpOrderGeneral(this),
            address: new ErpOrderAddress(this),
            document: new ErpOrderDocument(this),
            shipment: new ErpOrderShipment(this)
        }
        if (window.siteFeatures && window.siteFeatures.wms)
            this.childs.wms = new ErpOrderWMS(this)
        this.bindListeners();
        if (this.id) {
            await this.getEntity();
            await Utils.showLoader();
        } else {
            await Utils.createSelects(this.data, (elem: any) => { return this.onSelectGetFilterOptions(elem)}, (data: any, entity: any, element: any) => { return this.onSelectFilterResults(data, entity, element)});
        }
        await this.loadOrderStates();
        await Utils.hideLoader();
    }

    protected getEntityData(elem: any) {
        return ErpOrderEntity.getEntityData(elem)
    }

    async loadOrderStates() {
        const deliveryStatusSelect = document.querySelector("#erp_order_delivery_status_id") as HTMLSelectElement;
        const statusSelect = document.querySelector("#erp_order_status_id") as HTMLSelectElement;

        if (this.data.delivery_status) {
            deliveryStatusSelect.innerHTML = `<option selected value='${this.data.delivery_status.id}'>${this.data.delivery_status.name}</option>`;
            this.data.delivery_status.to.forEach((to: any) => {
                deliveryStatusSelect.innerHTML += `<option value='${to.id}'>${to.name}</option>`;
            })
        }
        if (this.data.status) {
            statusSelect.innerHTML = `<option selected value='${this.data.status.id}'>${this.data.status.name}</option>`;
            this.data.status.to.forEach((to: any) => {
                statusSelect.innerHTML += `<option value='${to.id}'>${to.name}</option>`;
            })
        }
    }

    createStateOptions(data: any) {
        let html = ``;
        data.transitions.forEach((state: any) => {
            html += `<option value='${state.technicalName}' data-transition='${state.actionName}'>${state.name}</option>`;
        })

        return html;
    }

    bindListeners() {
        jQuery("#new_erp_order_line_item_product_id").on("select2:ajax_done", async (e, data: any) => {
            console.log("data", data)
            if (data && data.status === 200 && data.data.length === 1 && data.data[0].is_serial_match === true) {
                    const mapData = data.data[0]
                    await Utils.entity.upsert({items: [{
                            quantity: 1,
                            serials: [mapData.serial],
                            warehouse_id: null,
                            warehouse_location_id: mapData.warehouse_location.id,
                            warehouse_pallet_id: mapData.warehouse_pallet ? mapData.warehouse_pallet.id : null
                        }], order_id: this.id, product_id: mapData.product.id, customer_id: this.data.customer.uuid}, "erp/order_items")
                    this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                    const table = document.querySelector("#erp_order_line_item_add_warehouse_list_table tbody") as HTMLElement;
                    table.innerHTML = "";
                    await this.getEntity()
                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddErpOrderLineItem") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    const elem = document.querySelector("#addErpOrderLineItemForm") as HTMLFormElement;
                    jQuery("#new_erp_order_line_item_product_id").val("").trigger("change").html("");
                    elem.reset();
            }
        });

        jQuery("#new_erp_order_line_item_product_id").on("select2:select", async (e, data: any) => {
            await this.addWarehouseInfo(e);
        });
        (document.querySelector("#erp_order_save") as HTMLButtonElement).addEventListener("click", async (e: any) => {
            await Utils.showLoader()
            const data = this.getEntityData(document.querySelector("div.row[data-entity-id]"));
            await Utils.entity.upsert(data, "erp/orders")

            const deliveryStatusSelect = document.querySelector("#erp_order_delivery_status_id") as HTMLSelectElement;
            const statusSelect = document.querySelector("#erp_order_status_id") as HTMLSelectElement;
            deliveryStatusSelect.innerHTML = '';
            statusSelect.innerHTML = '';
            await this.getEntity();
            await this.loadOrderStates();
            await Utils.hideLoader();
        });

        (document.querySelector("#erp_order_line_item_quantity") as HTMLInputElement).addEventListener("keyup", (e) => {
            this.checkQuantityProductQuantity();
        });

        jQuery("body").delegate(".line_item_warehouse_map", "keyup change", (e) => {
            e.preventDefault();
            const target = e.target;
            const maxQuantity = parseInt(target.getAttribute("data-max-quantity"));
            const currentQuantity = parseInt(target.value);
            if(maxQuantity < currentQuantity) {
                target.value = String(maxQuantity);
            }
            this.checkQuantityProductQuantity();
        })
    }

    checkQuantityProductQuantity() {
        const fullQuantity = parseInt((document.querySelector("#erp_order_line_item_quantity") as HTMLInputElement).value);
        let usedQuantity = 0;
        const inputs = document.querySelectorAll(".line_item_warehouse_map");
        inputs.forEach((input: any) => {
            usedQuantity += parseInt(input.value)
        })
        const saveBtn = document.querySelector("#erp_line_item_add_save_button") as HTMLButtonElement
        if (fullQuantity === usedQuantity) {
            saveBtn.removeAttribute("disabled");
        } else {
            saveBtn.setAttribute("disabled", "disabled");
        }
    }

    async addWarehouseInfo(data: any) {
        if (data.params.data.id) {
            console.log(this.data, data)
            const stockInfo = await Utils.entity.getAll('erp/stocks', null, {product_id: data.params.data.id, customer_id: this.data.customer.uuid});
            let html = '';
            const tbody = document.querySelector("#erp_order_line_item_add_warehouse_list_table tbody") as HTMLElement;
            tbody.innerHTML = "";
            stockInfo.data.forEach((stock: any) => {
                let name = `${stock.warehouse.name}, ${stock.warehouse_location.name}`
                if (stock.warehouse_pallet) {
                    name += `, ${stock.warehouse_pallet.name}`
                }
                let options = ``
                stock.serials.forEach((sn: string) => {
                    options += `<option value='${sn}'>${sn}</option>`
                })
                let availableStock = stock.stock
                if (availableStock > 0) {

                    html += `<tr>
<td style="width: 100%;">
<div class="row align-items-center">
 <div class="col-12 mb-1" style="font-size:12px;">${name}</div>
 <div class="col-6 mb-1">
 <input class="form-control line_item_warehouse_map" value="0" type="number" min="0" max="${availableStock}" data-max-quantity="${availableStock}" data-warehouse-id="${stock.warehouse.uuid}" data-warehouse-location-id="${stock.warehouse_location.uuid}" ${stock.warehouse_pallet ? `data-warehouse-pallet-id="${stock.warehouse_pallet.uuid}"` : ``} />

</div>
 <div class="col-6 mb-1"> / ${availableStock}</div>
 <div class="col-12">
 <label class="form-label">Serials: </label>
 <select class="form-select" multiple>
 ${options}
</select>
 </div>
</td>
</tr>`
                }
            })
            tbody.innerHTML = html;
            console.log("Stock Info", stockInfo)
        }
    }
    async updateEntity(data: any) {
        await super.updateEntity(data);
        const tagifyElem = document.querySelector('#erp_order_tracking_numbers') as HTMLInputElement
        const tagifyList = new Tagify(tagifyElem, {
            dropdown: {
                position: 'text',
                enabled: 1
            }
        });

        tagifyElem.addEventListener('change', async (e: any) => {
            const v = JSON.parse(e.target.value)
            //@ts-ignore
            const values = v.map((e: any) => e["value"]);
            //@ts-ignore
            const orderUuid = document.querySelector("#erp_order_id")?.value as string
            await Utils.entity.upsert({uuid: orderUuid, tracking_numbers: values}, 'erp/orders')
        });
        tagifyElem.addEventListener('remove', async (e: any) => {
            const v = JSON.parse(e.target.value)
            //@ts-ignore
            const values = v.map((e: any) => e["value"]);
            //@ts-ignore
            const orderUuid = document.querySelector("#erp_order_id")?.value as string
            await Utils.entity.upsert({uuid: orderUuid, tracking_numbers: values}, 'erp/orders')
        });

        const button = document.querySelector('#erp_order_tracking_numbers_add') as HTMLButtonElement;

        button.addEventListener('click', () => {
            tagifyList.addEmptyTag();
        });

        const input = document.querySelector("#erp_order_ticket_uuid") as HTMLInputElement;
        const inputParent = input.closest("div[class^='col-']") as HTMLDivElement;
        const row = inputParent.closest("div.row") as HTMLDivElement;
        const buttonCol = row.querySelector("div.col-4")
        if (buttonCol) {
            row.removeChild(buttonCol)
        }
        if (data.ticket) {
            inputParent.classList.remove("col-12")
            inputParent.classList.remove("col-8")
            inputParent.classList.add("col-8")
            const buttonDiv = document.createElement("div")
            buttonDiv.classList.add("col-4");
            buttonDiv.innerHTML = `<a href="/${currentLocale}/ticket/tasks/${data.ticket.uuid}"><button class="btn btn-primary w-100">Open</button></a>`
            row.appendChild(buttonDiv)
        }
    }


    protected async addLinePallet(data: any, orderId = null) {
        await UtilsErp.addPalletOrder(data.warehousePalletId, this.data.uuid);
        (document.querySelector("#erp_order_save") as HTMLButtonElement).removeAttribute("disabled");
    }
    onSelectGetFilterOptions(elem: any) {
        console.log("elem", elem, this.data)
        if (elem.getAttribute("id") === "new_erp_order_line_item_product_id") {
            return {
                customer_id: this.data.customer.uuid
            }
        } else if (elem.getAttribute("data-entity") === "tickets") {
            console.log(this.data)
            return {
                customer_id: this.data.customer.uuid
            }
        } else if (elem.getAttribute("id") === "erp_pallet_warehouse_location_pallet_id") {
            return {
                warehouse_id:  (document.querySelector("#erp_pallet_warehouse_id option:checked") as HTMLOptionElement).value
            }
        } else {
            return {
            }
        }
    }

}