import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'

import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import FeatureEntity from './entity';

export default class SettingsFeaturesNew extends DefaultController {
    async init() {
        this.entity = "site_features"
        await super.init();
    }

    getEntityData(elem: any) {
        return FeatureEntity.getEntityData(elem)
    }

    bindListeners() {
        // @ts-ignore
        document.querySelectorAll(".col-4[data-product-id]").forEach((elem: HTMLElement) => {
            const productId = elem.getAttribute("data-product-id") as string
            const featureId = elem.getAttribute("data-site-feature-id")
            const currentUsers = parseInt(elem.getAttribute("data-site-feature-users") as string || "0")
            const packagePrice = parseFloat(elem.getAttribute("data-price") as string)
            const priceUser = parseFloat(elem.getAttribute("data-price-user") as string)
            const userInput = elem.querySelector("input[type='number']") as HTMLInputElement | null
            const buyButton = elem.querySelector(".licenses_add_feature") as HTMLButtonElement
            const infoDiv = elem.querySelector(".user_info_img") as HTMLElement
            const calc = () => {
                if (userInput) {
                    let totalPrice = 0
                    let userVal = parseInt(userInput.value || "0")
                    if (userVal < currentUsers || isNaN(userVal)) {
                        userInput.value = `${currentUsers}`
                        userVal = currentUsers
                    }
                    if (!featureId) {
                        totalPrice += packagePrice
                    }
                    const userDiff = userVal - currentUsers
                    if (userDiff > 0) {
                        infoDiv.innerHTML = `<div class="user-progress d-flex align-items-center gap-3">
                    <div class="d-flex align-items-center gap-1">
                      <i class="ti ti-chevron-up text-success"></i>
                      <small class="text-muted">+${userDiff}</small>
                    </div>
                  </div>`
                    } else {
                        infoDiv.innerHTML = ''
                    }
                    totalPrice += (userDiff * priceUser);
                    (elem.querySelector(".price_total") as HTMLElement).innerHTML = `${totalPrice.toFixed(2).replace(".", ",")}€ / Monat`
                }
            }
            if (userInput) {
                userInput.addEventListener('keyup', (e) => {
                    calc()
                })
                userInput.addEventListener('change', (e) => {
                    calc()
                })
                calc()
            }
            buyButton.addEventListener('click', async (e) => {
                e.preventDefault();
                if (userInput) {
                    let userVal = parseInt(userInput.value || "0")
                    if (userVal < currentUsers || isNaN(userVal)) {
                        userInput.value = `${currentUsers}`
                        userVal = currentUsers
                    }
                    const userDiff = userVal - currentUsers
                    await Utils.license.buy(productId, userDiff)
                    document.location.reload()
                }
            })
        })
    }
}