import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import ErpOrderEntity from "../../entity";
import UtilsErp from "../../../../../utils/erp";
import UtilsEntity from "../../../../../utils/entity";
export default class ErpOrderAddress{
    private parent: any;
    private entity = "erp/order";
    private toastr: any;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...ErpOrderEntity.getEntityData(elem), id: this.parent.id}
    }

    bindListeners() {
        jQuery("#order_customer_delivery_address_id").on("select2:select", async (e: any) => {
            const id = e.params.data.id;
            const location_r = await UtilsEntity.get(id, 'customer_locations')
            const data = location_r.data[0];
            console.log("data", data);
            /*
            erp_order_deliveryAddress_firstName
            erp_order_deliveryAddress_lastName
            erp_order_deliveryAddress_street
            erp_order_deliveryAddress_zipcode
            erp_order_deliveryAddress_city
             */
            (document.querySelector("#erp_order_deliveryAddress_fltools_uuid") as HTMLInputElement).value = id;
            (document.querySelector("#erp_order_deliveryAddress_firstName") as HTMLInputElement).value = data.first_name;
            (document.querySelector("#erp_order_deliveryAddress_lastName") as HTMLInputElement).value = data.last_name;
            (document.querySelector("#erp_order_deliveryAddress_street") as HTMLInputElement).value = data.full_street;
            (document.querySelector("#erp_order_deliveryAddress_zipcode") as HTMLInputElement).value = data.postcode;
            (document.querySelector("#erp_order_deliveryAddress_city") as HTMLInputElement).value = data.city;
        });
    }

    async update(data: any) {
        await Utils.updateElements(data, '', (document.querySelector("#erp_order_address") as HTMLElement))
        const addresses = data.customer.customerLocations
        jQuery('#order_customer_delivery_address_id').empty().trigger('change');
        for (const address of addresses) {
                const dataOptions = {
                    id: address.id,
                    text: `${address.name_formatted}`
                };
                const selected = address.id === data.customer_location.id
                const newOption = new Option(dataOptions.text, dataOptions.id, selected, selected);
                jQuery('#order_customer_delivery_address_id').append(newOption);
        }
        jQuery('#order_customer_delivery_address_id').trigger("change");
        jQuery('#order_customer_delivery_address_id').removeAttr("disabled")


    }
}