import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../../utils'
import VacationDayAllowanceEntity from "../../../../../vacation_day_allowance/entity";
export default class TeamsSettingsUserOverviewShowVacationDayAllowance{
    private parent: any;
    private tableElem: any;
    private datatable: any;
    private entity = "vacation_day_allowances";
    private toastr: any;
    private editModal: any;
    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.tableElem = jQuery('.settings_users_show_vacation_day_allowances')
        this.editModal = new bootstrap.Modal((document.querySelector("#teamsSettingsUsersVacationDayAllowancesModal") as HTMLElement));
        this.createTable();
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...VacationDayAllowanceEntity.getEntityData(elem), user_id: this.parent.id}
    }

    bindListeners() {
        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity, this.parent.id, this.parent.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#teamsSettingsUsersVacationDayAllowancesForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form)
                this.editModal.show();
            }
        });

        (document.querySelector("#teamsSettingsUsersVacationDayAllowancesForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#teamsSettingsUsersVacationDayAllowancesForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity, this.parent.id, this.parent.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('vacation_day_allowance.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        this.tableElem.delegate(".delete-record", "click", async (e: any) => {
            e.preventDefault();const confirm = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity, this.parent.id, this.parent.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('vacation_day_allowance.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('vacation_day_allowance.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });

        (document.querySelector("#addnewVacationDayAllowanceForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#addnewVacationDayAllowanceForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity, this.parent.id, this.parent.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddVacationDayAllowance") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    this.toastr.success(`${Utils.translate('vacation_day_allowance.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }

    createTable() {
        this.datatable = new DataTable(this.tableElem, {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/users/${this.parent.id}/vacation_day_allowances`,
            columns: [
                {data: 'from'},
                {data: 'to'},
                {data: 'amount'},
                {data: 'uuid'}
            ],
            order: [[1, 'desc']],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 3,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddVacationDayAllowance'
                    }
                }
            ]
        });
    }
}