import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import ErpDocumentEntity from "../../../document/entity";
import * as pdfjsLib from "pdfjs-dist"
import SignaturePad from "signature_pad"

export default class ErpOrderDocument{
    private parent: any;
    private entity = "erp/documents";
    private toastr: any;
    private datatable: any;
    private newItemsDatatable: any;

    private pdfDocumentId = "";
    private pdfDownloadUrl = "";
    private pdfModal: bootstrap.Modal;
    private pdfSignModal: bootstrap.Modal;
    private signaturePad: SignaturePad | undefined;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.pdfModal = new bootstrap.Modal((document.querySelector("#erpOrderDocumentPdfModal") as HTMLElement));
        this.pdfSignModal = new bootstrap.Modal((document.querySelector("#erpOrderDocumentPdfSignModal") as HTMLElement));
        this.setupPdfReader()
        this.bindListeners();
    }

    async setupPdfReader() {
        pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
    }

    async setupSigning() {
        const canvas = document.querySelector('#erpOrderDocumentPdfSignCanvas') as HTMLCanvasElement | null;
        if (canvas) {
            if (!this.signaturePad) {
                this.signaturePad = new SignaturePad(canvas);
            } else {
                this.signaturePad.clear();
            }
        }
    }

    async saveSignature() {
        if (this.signaturePad) {
            const image = this.signaturePad.toDataURL();
            await Utils.erp.saveSignature(this.pdfDocumentId, this.pdfDownloadUrl, image)
        }
    }

    async renderPdfPage(page) {
        console.log("Render", page)
        const canvas = document.createElement("canvas")
        const scale = 1.5;
        const viewport = page.getViewport({scale: scale});
        const context = canvas.getContext('2d');
        if (context) {
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            const renderTask = page.render(renderContext);
            await renderTask.promise
        }
        canvas.style.maxWidth = "100%"
        return canvas
    }
    async loadPdf(downloadUrl: string) {
        pdfjsLib.getDocument(`${document.location.origin}/${downloadUrl}`).promise.then(async (pdf) => {
            const canvasHolder = document.querySelector('#erpOrderDocumentPdfCanvases') as HTMLCanvasElement | null;
            if (canvasHolder) {
                for (let i = 1; i <= pdf.numPages; i++) {
                    const c = await this.renderPdfPage((await pdf.getPage(i)))
                    canvasHolder.appendChild(c)
                }
            }
            this.pdfModal.show();
            const button = document.querySelector('#erpOrderDocumentPdfModalSignButton') as HTMLButtonElement;

            button.style.bottom = `${window.siteConfig.document.y}px`
            button.style.right = `${window.siteConfig.document.x}px`
            button.style.width = window.siteConfig.document.width;
            button.style.height = window.siteConfig.document.height;
        })
    }

    getEntityData(elem: any) {
        return {...ErpDocumentEntity.getEntityData(elem), order_id: this.parent.id}
    }

    bindListeners() {

        jQuery(".datatables-erp-order-document tbody").delegate(".sign-record", "click", async (e: JQuery.TriggeredEvent) => {
            this.pdfDownloadUrl = e.currentTarget.getAttribute("data-url");
            this.pdfDocumentId = e.currentTarget.getAttribute("data-document-id");
            await this.loadPdf(this.pdfDownloadUrl);

        });
        (document.querySelector('#erpOrderDocumentPdfModalSignButton') as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            await this.setupSigning();
            this.pdfSignModal.show();
        });

        (document.querySelector('#erpOrderDocumentPdfSignSave') as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            await this.saveSignature();
            this.pdfSignModal.hide();
            this.pdfModal.hide();
            await this.parent.getEntity();
        });
        (document.querySelector("#addErpOrderDocumentForm") as HTMLFormElement).addEventListener("submit", async (e: any) => {
            e.preventDefault();
            const elem = document.querySelector("#addErpOrderDocumentForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                let itemConfig: any = []
                let failed = false //This is not pretty, but there is no way of breaking of DTs every() I'm afraid
                if (this.newItemsDatatable) {
                    const itemRows = this.newItemsDatatable.rows({selected: true})
                    itemRows.every((rowIndex: any) => {
                        const row = this.newItemsDatatable.row(rowIndex).node()
                        const data = this.newItemsDatatable.row(rowIndex).data()
                        const selectedQuantity = parseInt(row.querySelector(".erp_document_new_quantity").value);
                        // @ts-ignore
                        const selectedSerials = Array.from(row.querySelectorAll("select.form-select option:checked")).map((o: HTMLInputElement) => {
                            return o.value
                        });
                        if (data.serials.length > 0 && selectedQuantity !== selectedSerials.length) {
                            this.toastr.error(`Serials are not matching quantity!`, `${Utils.translate('generic.failed')}`)
                            failed = true;
                        }
                        let mapIds: any[] = [];
                        console.log("D", data)
                        selectedSerials.forEach((serial: string, i: number) => {
                            const map = data.data.data.filter((d: { serial: string; }) => d.serial === serial)[0]
                            mapIds.push(map.id)
                        })
                        itemConfig.push({
                            map_ids: mapIds,
                        })
                    })
                } else {
                    this.parent.data.items.forEach((item: any) => {
                        itemConfig.push({
                            mapIds: item.data.map((d: { id: any; }) => d.id)
                        })
                    })
                }
                const warehouseId = (document.querySelector("#erp_document_warehouse_id option:checked") as HTMLOptionElement).value
                if (!failed) {
                    await Utils.showLoader();
                    await Utils.entity.upsert({...this.getEntityData(elem), items: itemConfig, warehouse_id: warehouseId}, this.entity)
                    this.toastr.success(`${Utils.translate('erp.document.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)


                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddErpOrderDocument") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    await Utils.hideLoader();
                    await this.parent.getEntity()
                }
            }
        });

        jQuery("#erp_document_document_type_id").on("select2:select", (e: any) => {
            (document.querySelector("#erp_document_document_type_name") as HTMLInputElement).value = e.params.data.data.technicalName;
        })
    }


    createDataTable() {
        this.datatable = new DataTable(".datatables-erp-order-document", {
            processing: true,
            layout: {
                topEnd: ['search', 'buttons']
            },
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddErpOrderDocument'
                    }
                }
            ]
        });
    }

    async generateWarehouseList() {
        let itemConfig: any = []
        let failed = false //This is not pretty, but there is no way of breaking of DTs every() I'm afraid
        if (this.newItemsDatatable) {
            const itemRows = this.newItemsDatatable.rows({selected: true})
            itemRows.every((rowIndex: any) => {
                const row = this.newItemsDatatable.row(rowIndex).node()
                const data = this.newItemsDatatable.row(rowIndex).data()
                const selectedQuantity = parseInt(row.querySelector(".erp_document_new_quantity").value);
                // @ts-ignore
                const selectedSerials = Array.from(row.querySelectorAll("select.form-select option:checked")).map((o: HTMLInputElement) => {
                    return o.value
                });
                if (data.serials.length > 0 && selectedQuantity !== selectedSerials.length) {
                    this.toastr.error(`Serials are not matching quantity!`, `${Utils.translate('generic.failed')}`)
                    failed = true;
                }
                let mapIds: any[] = [];
                selectedSerials.forEach((serial: string, i: number) => {
                    const map = data.data.data.filter((d: { serial: string; }) => d.serial === serial)[0]
                    mapIds.push(map.id)
                })
                itemConfig.push({
                    map_ids: mapIds,
                })
            })
        } else {
            this.parent.data.items.forEach((item: any) => {
                itemConfig.push({
                    mapIds: item.data.map((d: { id: any; }) => d.id)
                })
            })
        }
        const warehouses = await Utils.erp.generateDocumentWarehouse(itemConfig)
        if (warehouses.status === 200) {
            const warehouseData = warehouses.data
            const select = document.querySelector("#erp_document_warehouse_id") as HTMLSelectElement
            if (warehouseData.length === 0) {
                select.setAttribute("disabled", "disabled")
                select.value = ""
            } else {
                select.removeAttribute("disabled")
                let html = ""
                warehouseData.forEach((d: any, i: number) => {
                    html += `<option value="${d.id}" ${i === 0 ? 'selected' : ''}>${d.name}</option>`
                })
                select.innerHTML = html;
            }
        }
    }
    async update(data: any) {
        if (this.datatable) {
            this.datatable.destroy();
        }

        await Utils.updateElements(data, '', (document.querySelector("#erp_order_document") as HTMLElement))
        const table = document.querySelector(".datatables-erp-order-document tbody") as HTMLElement;
        table.innerHTML = "";
        data.documents.forEach((document: any) => {
            let tr = `<tr>
                <td>${new Date(Date.parse(document.created_at)).toLocaleString()} ${document.signed ? `<b>(Signiert)</b>` : ``}</td>
                <td>${document.number}</td>
                <td>${Utils.translate(`erp.document.types.${document.document_template.document_type}`)}</td>
                <td>${document.user ? document.user.name : ""}</td>
                <td><div class="d-flex align-items-center"> 
                <a href="${document.url}" target="_blank" class="text-body download-record"> <i class="ti ti-download ti-sm me-2"></i> </a>`
                if (!document.signed) {
                    tr += `<a href="#" data-document-id="${document.id}" data-url="${document.url}" class="text-body sign-record"> <i class="ti ti-writing-sign ti-sm me-2"></i> </a>`
                }
              tr += `</div></td></tr>`
            table.innerHTML = table.innerHTML + tr;
        });
        if (this.newItemsDatatable) {
            this.newItemsDatatable.destroy();
        }
        const newItemData: { uuid: any; name: any; quantity: any; serials: any; }[] = []
        data.items.forEach((item: any) => {
            newItemData.push({
                data: item,
                uuid: item.product.id,
                name: item.product.name,
                quantity: item.data.length,
                serials: item.data.map((d: { serial: any; }) => d.serial)
            })
        })
        this.newItemsDatatable = new DataTable("#erp_document_new_items_table", {
            initComplete: function() {
                this.api().rows().select()
            },
            data: newItemData,
            processing: true,
            createdRow: ( row: HTMLTableRowElement, data: any, dataIndex: number ) => {
                row.setAttribute("data-line-item-id", data.uuid);
            },
            select: {
                selector: 'td:first-child',
                headerCheckbox: true,
                style: 'multi'
            },
            columns: [
                {data: 'uuid'},
                {data: 'name'},
                {data: 'serials'},
                {data: 'quantity'},
            ],
            columnDefs: [
                {
                    targets: 0,
                    orderable: false,
                    render:DataTable.render.select(),
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let html = `<select class="form-select ${data.length === 0 ? 'd-none' : ''}" multiple>`;
                        data.forEach((serial: string) => {
                            html += `<option value='${serial}' selected>${serial}</option>`
                        })
                        html += '</select>'
                        return html;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="row align-items-center"><div class="col-5"><input type="number" class="form-control form-control-sm erp_document_new_quantity" value="${data}"/></div><div class="col-1">/</div><div class="col-5">${data}</div></div>`;
                    },
                }
            ],
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-12"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-12 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
        this.newItemsDatatable.on('select deselect', async () => {
            await this.generateWarehouseList()
        })
        this.createDataTable();
        await this.generateWarehouseList()
    }
}