import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'

import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import FeatureEntity from './entity';

export default class SettingsFeaturesList extends DefaultController {
    tableElem: any;
    datatable: any;
    private editModal: any;
    private maxUsers = 0;

    async init() {
        this.entity = "features"
        this.tableElem = jQuery('.datatables-users')
        this.editModal = bootstrap.Modal.getOrCreateInstance((document.querySelector("#editSiteFeatureModal") as HTMLElement));
        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return FeatureEntity.getEntityData(elem)
    }

    bindListeners() {
        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editSiteFeatureForm") as HTMLFormElement);

                form.reset()
                await Utils.updateElements(data, '', form)
                jQuery("#site_feature_users").val("").trigger("change");
                data.users.forEach((user: any) => {
                    const $newOption = jQuery("<option selected='selected'></option>").val(user.uuid).text(user.name)
                    jQuery("#site_feature_users").append($newOption).trigger('change');
                })
                this.maxUsers = data.allowed_users;
                // @ts-ignore
                this.editModal.show();
            }
        });
        jQuery("#site_feature_users").on("select2:selecting", async (e: any) => {
            const form = (document.querySelector("#editSiteFeatureForm") as HTMLFormElement);
            const currentUsers = Array.from(form.querySelectorAll("#site_feature_users option:checked")).map(o => o.value)
            if (this.maxUsers < currentUsers.length+1) {
                e.preventDefault();
                this.toastr.error(`${Utils.translate('site_feature.name')} ${Utils.translate('site_feature.messages.max_licenses_reached')}`, `${Utils.translate('generic.error')}`)

            }
        });
        (document.querySelector("#editSiteFeatureForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editSiteFeatureForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    this.editModal.hide()
                    this.toastr.success(`${Utils.translate('site_feature.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('site_feature.name')} ${Utils.translate('generic.messages.not_updated')}`, `${Utils.translate('generic.error')}`)
                }
            }
        });

    }

    createTable() {
        this.datatable = new DataTable(this.tableElem, {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/features`,
            columns: [
                {data: 'feature'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${Utils.translate(`site_products.products.${data}.name`)}`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${new Date(Date.parse(full.valid_from)).toLocaleString()}`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${new Date(Date.parse(full.valid_to)).toLocaleString()}`;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${full.users.length}/${full.allowed_users}`;
                    },
                },
                {
                    targets: 4,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}"  class="text-body renew-record">
                                    <i class="ti ti-refresh ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}"  class="text-body edit-record">
                                    <i class="ti ti-user ti-sm me-2"></i>
                                  </a>
                                </div>`
                    },
                },
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    action: () => {
                        document.location.href = `/${(window as any).currentLocale}/settings/features/new`
                    }
                }
            ]
        });
    }
}