import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import TicketServiceMapEntity from "../../../ticket_service_map/entity";


export default class TicketShowLogs {
    private parent: any;
    private tableElem: any;
    private datatable: any;
    private entity = "services";
    private toastr: any;


    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...TicketServiceMapEntity.getEntityData(elem), ticket_id: this.parent.id}
    }

    bindListeners() {
    }

    createDataTable() {
        this.datatable = new DataTable(".datatables-ticket-logs", {
            processing: true,
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }

    async update(data: any) {
        if (this.datatable) {
            this.datatable.destroy();
        }
        const table = document.querySelector(".datatables-ticket-logs tbody") as HTMLElement;
        if (table) {
            table.innerHTML = "";
            if (data.logs) {
                data.logs.forEach((log: any) => {
                    const tr = `<tr>
<td>${log.created_at}</td>
<td>${log.user.name}</td>
<td>${log.changes.join(", ")}</td>
                </tr>`
                    table.innerHTML = table.innerHTML + tr;
                });
                this.createDataTable();
            }
        }
    }
}