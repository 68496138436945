import Utils from "../../../utils";

export default class WarehouseLocationEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#warehouse_location_id") ? (elem.querySelector("#warehouse_location_id") as HTMLInputElement).value : null,
            name: (elem.querySelector("#warehouse_location_name") as HTMLInputElement).value,
            width: parseInt((elem.querySelector("#warehouse_location_width") as HTMLInputElement).value),
            height: parseInt((elem.querySelector("#warehouse_location_height") as HTMLInputElement).value),
            length: parseInt((elem.querySelector("#warehouse_location_length") as HTMLInputElement).value),
            internal_name: (elem.querySelector("#warehouse_location_internal_name") as HTMLInputElement).value || Utils.makeId(7)
        }
    }
}