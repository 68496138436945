
import * as bootstrap from 'bootstrap'
import TicketOverviewShow from "./show";
import TicketShowReports from "./show/reports";
import TicketShowDocuments from "./show/documents";
import TicketShowOrders from "./show/orders";
import TicketShowLogs from "./show/logs";
import TicketShowChildren from "./show/children";
import TicketShowServicesBSM from "./show/services_bsm";
import Utils from '../../../utils'

export default class TicketOverviewShowBSM extends TicketOverviewShow {
    addChilds() {
        this.childs = {
            services: new TicketShowServicesBSM(this),
            reports: new TicketShowReports(this),
            documents: new TicketShowDocuments(this),
            orders: new TicketShowOrders(this),
            logs: new TicketShowLogs(this),
            children: new TicketShowChildren(this)
        }
    }
    parseComments(data: any) {
        let entries: { type: string; at: any; body: string; }[] = []
        data.logs.forEach((log: any) => {
            if (log.changes && log.changes.length > 0) {

                entries.push({
                    type: "log",
                    at: log.created_at,
                    body: this.addLog(log)
                })
            }
        });
        data.reports.forEach((report: any) => {
            entries.push({
                type: "report",
                at: report.created_at,
                body: this.addReport(report)
            })
        });
        data.comments.forEach((comment: any) => {
            entries.push({
                type: "comment",
                at: comment.created_at,
                body: this.addComment(comment)
            })
        });
        console.log("entries", entries)
        // @ts-ignore
        entries = entries.sort((a, b) => {
            if (new Date(Date.parse(a.at)).getTime() > new Date(Date.parse(b.at)).getTime()) {
                return 1;
            } else if (new Date(Date.parse(a.at)).getTime() < new Date(Date.parse(b.at)).getTime()) {
                return -1;
            } else {
                return 0;
            }
        });
        console.log("entries", entries)

        let html = `<div class="row">`
        entries.reverse().forEach((entry: any) => {
            html += entry.body;
        })
        html += `</div>`;
        this.chatHistoryBody.innerHTML = html;
    }

    processChatAdd(r: any) {
        const html = this.addComment(r.data[0])
        this.chatHistoryBody.innerHTML = html + this.chatHistoryBody.innerHTML
    }

    addLog(log: any) {
        let html = '';
        html += `<div class="col-12 mb-4">
            <div class="card" style="border-top: 5px solid rgba(153, 159, 255, 0.4)">
            <div class="card-body">`
        html += `<b style="margin-bottom: 10px;">${log.user.name} at ${Utils.formatDate(log.created_at)}</b><br />`

        html += log.changes.join("<br />")
        html += `</div> </div> </div>`
        return html
    }

    addReport(report: any) {
        let html = '';
        html += `<div class="col-12 mb-4">
            <div class="card" style="border-top: 5px solid rgba(255, 72, 72, 0.4)">
            <div class="card-body">`
        html += `<b style="margin-bottom: 10px;">${report.user.name} at ${Utils.formatDate(report.created_at)}</b><br />`
        let attachmentHtml = 'No attachments available.'
        if (report.attachments.length > 0) {
            attachmentHtml = ''
            report.attachments.forEach((attachment: any) => {
                if (attachment.url.indexOf(".png") !== -1 || attachment.url.indexOf(".jpg") !== -1 || attachment.url.indexOf(".jpeg") !== -1) {
                    attachmentHtml += ` <a href='${attachment.url}' target="_blank"><img style="max-height: 100px;" src="${attachment.url}" class="img-fluid" /></a>`
                } else {
                    attachmentHtml += `<a href='${attachment.url}' target="_blank">${attachment.name}</a>`
                }
            });
        }
        html += `
                    <div class="row mb-4">
                        <div class="col-6"> 
                            <h4>Description</h4>
                            ${report.description}
                        </div> 
                        <div class="col-6"> 
                            <h4>Attachments</h4>
                            ${attachmentHtml}
                        </div> 
                    </div>
                    <div class="row mb-4">
                        <div class="col-3"> 
                            <h4>Appointment</h4>
                            ${report.calendar_appointment ? `${Utils.formatDate(report.calendar_appointment.from)}` : 'Not bound to an appointment.'}
                        </div> 
                        <div class="col-3"> 
                            <h4>Arrival</h4>
                            ${Utils.formatDate(report.arrival_from)} - ${Utils.formatDate(report.arrival_to)} (${report.arrival_time}min)<br />${report.arrival_resource ? `${report.arrival_resource.name}, ` : ``}${report.arrival_distance ? `${report.arrival_distance}km` : ``}
                        </div> 
                        <div class="col-3"> 
                            <h4>Work</h4>
                            ${Utils.formatDate(report.spent_from)} - ${Utils.formatDate(report.spent_to)} (${report.spent_time}min)
                        </div> 
                        <div class="col-3"> 
                            <h4>Return</h4>
                            ${Utils.formatDate(report.return_from)} - ${Utils.formatDate(report.return_to)} (${report.return_time}min)<br />${report.return_resource ? `${report.return_resource.name}, ` : ``}${report.return_distance ? `${report.return_distance}km` : ``}
                        </div> 
                    </div>`
        html += `</div> </div> </div>`
        return html
    }

    addComment(comment: any) {
        let html = '';
        html += `<div class="col-12 mb-4">
            <div class="card" style="border-top: 5px solid ${comment.internal ? 'rgba(255, 217, 78, 0.4)' : 'rgba(10, 197, 50, 0.4)'}">
            <div class="card-body">`
        if (comment.origin) {
            html += `<b style="margin-bottom: 10px;">${comment.from} at ${Utils.formatDate(comment.created_at)} | <a href="/${(<any>window).currentLocale}/ticket/tasks/${comment.origin.uuid}">${comment.origin.title_formatted}</a></b><br />`
        } else {
            html += `<b style="margin-bottom: 10px;">${comment.from} at ${Utils.formatDate(comment.created_at)}</b><br />`
        }

        html += comment.description
        if (comment.attachments.length > 0) {
            const image_types = ["image/png", "image/jpeg", "image/svg+xml"]
            comment.attachments.forEach((attachment: any) => {
                const url = attachment.url;
                if (image_types.indexOf(attachment.type) !== -1) {
                    html += `<br /><img src="${url}" style="height: 120px;cursor: pointer;" class="img-thumbnail ticket_comment_img"/>`
                } else {
                    html += `${attachment.name}<br /><a href="${url}" target="_blank"><button class="btn btn-secondary"><i style="color:#FFF;" class="ti ti-download"></i>  Download</button> </a>`
                }
            })
        }
        html += `</div> </div> </div>`
        return html;
    }

    setupScrollbars() {
        //noop
    }
}