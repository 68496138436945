import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import AbsenceModeEntity from "../entity";

export default class AbsenceModeList extends DefaultController {
    tableElem: any;
    datatable: any;
    private editModal: any;

    async init() {
        this.entity = "absence_modes";
        this.tableElem = jQuery('.datatables-users')
        this.editModal = new bootstrap.Modal((document.querySelector("#editAbsenceMode") as HTMLElement));
        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return AbsenceModeEntity.getEntityData(elem)
    }

    bindListeners() {
        this.tableElem.delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('absence_mode.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('absence_mode.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });
        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editAbsenceModeForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form)
                if (data.book_mode !== "hourly") {
                    (form.querySelector("#absence_mode_hours") as HTMLInputElement).setAttribute("disabled", "disabled")
                } else {
                    (form.querySelector("#absence_mode_hours") as HTMLInputElement).removeAttribute("disabled")
                }
                this.editModal.show();
            }
        });
        (document.querySelector("#editAbsenceModeForm #absence_mode_book_mode") as HTMLFormElement).addEventListener("change", (e: any) => {
            const target = e.target as HTMLInputElement;
            if (target.value !== "hourly") {
                (document.querySelector("#editAbsenceModeForm #absence_mode_hours") as HTMLInputElement).setAttribute("disabled", "disabled")
            } else {
                (document.querySelector("#editAbsenceModeForm #absence_mode_hours") as HTMLInputElement).removeAttribute("disabled")
            }
        });
        (document.querySelector("#addNewAbsenceModeForm #absence_mode_book_mode") as HTMLFormElement).addEventListener("change", (e: any) => {
            const target = e.target as HTMLInputElement;
            if (target.value !== "hourly") {
                (document.querySelector("#addNewAbsenceModeForm #absence_mode_hours") as HTMLInputElement).setAttribute("disabled", "disabled")
            } else {
                (document.querySelector("#addNewAbsenceModeForm #absence_mode_hours") as HTMLInputElement).removeAttribute("disabled")
            }
        });
        (document.querySelector("#addNewAbsenceModeForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#addNewAbsenceModeForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddAbsenceMode") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    elem.reset();
                    this.toastr.success(`${Utils.translate('absence_mode.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        (document.querySelector("#editAbsenceModeForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editAbsenceModeForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('absence_mode.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }

    createTable() {
        this.datatable = new DataTable(this.tableElem, {
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/absence_modes`,
            columns: [
                {data: 'name'},
                {data: 'hours'},
                {data: 'deduct_from_vacation_days'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.book_mode === "hourly" ? `${data}h` : Utils.translate(`absence_mode.book_modes.${full.book_mode}`);
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data ? `<i class="ti ti-circle-check-filled ti-sm"></i>` : `<i class="ti ti-circle-x-filled ti-sm"></i>`;
                    },
                },
                {
                    targets: 3,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddAbsenceMode'
                    }
                }
            ]
        });
    }
}